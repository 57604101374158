import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { sendContact } from '../../../Features/Auth/authSlice'
import {
  BlueButton,
  FormContainer,
  FormContent,
  FormLabel,
  FormLabelError,
  FormRow,
  FormSuccessMessage,
  MainBox,
  MainBoxSmallHeader
} from '../../../Pages/PersonalInfo/Components'
import { RootState, useAppDispatch } from '../../../Store'

const StyledTextArea = styled.textarea<{ error?: boolean }>`
  width: 100%;
  height: 150px;
  border-radius: 2px;
  background-color: #ffffff;
  color: #000;
  outline: none;
  padding: 1rem;

  &::placeholder {
    font-style: italic;
  }
`

interface TestProps {
  data: any
  registry: string
}

const ResultContactForm: FC<TestProps> = props => {
  const dispatch = useAppDispatch()
  const queryParams = useLocation().search
  const history = useHistory()
  const { t } = useTranslation()

  const { authed, active_subscription } = useSelector(
    (state: RootState) => state.auth
  )

  const [moveForward, setMoveForward] = useState(false)

  const [content, setContent] = useState('')

  const [errorHappened, setErrorHappened] = useState(false)
  const [successHappened, setSuccessHappened] = useState(false)

  const sendMessage = () => {
    const fullDescription = `${props.registry} ${props.data.first_names} ${props.data.last_name} (${props.data.id})`
    const filledContent = `${fullDescription} \n\n ${content}`
    dispatch(
      sendContact({
        content: filledContent
      })
    ).then(response => {
      if ('error' in response) {
        setErrorHappened(true)
      } else {
        setSuccessHappened(true)
      }
    })
  }

  useEffect(() => {
    if (authed && active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/personal-info')
    } else if (authed && !active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/expired')
    }

    /**
     * User can be directed directly to correct form (login or registration) by using queryParams
     */
  }, [authed, active_subscription, moveForward, history, queryParams])

  return (
    <MainBox>
      <MainBoxSmallHeader>
        Ehdota lisäystä tai korjausta tietueeseen?
      </MainBoxSmallHeader>
      Rekisteritietueissa saattaa paikoin olla tallennusvirheitä. Voit lähettää
      korjausehdotuksesi alla olevalla lomakkeella. Lomake täydentää
      automaattisesti tiedon mistä tietueesta on kyse.
      {successHappened ? (
        <FormRow>
          <FormSuccessMessage>
            <br />
            <strong>{t('Thank you for your contact!')}</strong>
            <p>{t('We will answer you as soon as possible.')}</p>
          </FormSuccessMessage>
        </FormRow>
      ) : (
        <>
          <FormContainer
            action=""
            onSubmit={e => {
              e.preventDefault()
              sendMessage()
            }}
          >
            <FormRow>
              <FormContent>
                <FormLabel htmlFor="content">
                  {t('contact-form-message-title')}
                </FormLabel>
                <StyledTextArea
                  placeholder={t('')}
                  maxLength={255}
                  id="content"
                  value={content}
                  onChange={e => setContent(e.target.value)}
                />
              </FormContent>
            </FormRow>
            <FormRow>
              {errorHappened ? (
                <FormLabelError>
                  {t('Error happened. Message not delivered.')}
                </FormLabelError>
              ) : null}
            </FormRow>
            <FormRow>
              <BlueButton disabled={!content}>{t('Send')}</BlueButton>
            </FormRow>
          </FormContainer>
        </>
      )}
    </MainBox>
  )
}

export default ResultContactForm
