import {FC, useEffect, useState} from 'react'
import { RootState, useAppDispatch } from '../../Store'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Background,
  BlueButton, FormActionButtonGroup,
  FormContainer, FormContent, FormError, FormInfo,
  FormLabel,
  FormRow, FormSuccess,
  LeftSide,
  MainBox, MainBoxHeader,
  RightSide, StyledInputText,
  WhiteButtonLink
} from "./Components";
import {changePasswordUser} from "../../Features/Auth/authSlice";
import ContactForm from "./ContactForm";

const PasswordChangeScreen: FC = () => {
  const dispatch = useAppDispatch()
  const queryParams = useLocation().search
  const history = useHistory()
  const { t } = useTranslation()

  const {
    authed,
    active_subscription
  } = useSelector((state: RootState) => state.auth)

  const [moveForward, setMoveForward] = useState(false)

  const [old_password, setOldPassword] = useState('')
  const [new_password1, setNewPassword1] = useState('')
  const [new_password2, setNewPassword2] = useState('')
  const [errorHappened, setErrorHappened] = useState(false)
  const [successHappened, setSuccessHappened] = useState(false)
  
  const changePassword = () => {
    dispatch(changePasswordUser({ 
      old_password, new_password1, new_password2,
    })).then((response) => {
      if ("error" in response) {
        setErrorHappened(true)
      } else {
        setSuccessHappened(true)
      }
    })
  }
  
  useEffect(() => {
    if (authed && active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/personal-info')
    } else if (authed && !active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/expired')
    }
    
    if (successHappened) {
      const toRef = setTimeout(() => {
        setMoveForward(true)
        clearTimeout(toRef)
      }, 3000)
    }
    
  }, [authed, active_subscription, moveForward, successHappened, history, queryParams])

  return (
    <Background>
      <LeftSide>
        {errorHappened ? 
          <FormError>
            <strong>{t('ERROR')}</strong> {t('Password could not be changed.')}
          </FormError>
        : null}
        {successHappened ? 
          <FormSuccess>
            <strong>{t('OK')}</strong> {t('Password changed succesfully. Redirecting...')}
          </FormSuccess>
        : null}
        <MainBox>
          <MainBoxHeader>{t('Change your password')}</MainBoxHeader>
          <FormContent>
            {t('change-your-password-info')}            
          </FormContent>
          <FormContainer
            action=""
            onSubmit={e => {
              e.preventDefault()
              changePassword()
            }}
          >
            <FormRow>
              <FormContent>
                <FormLabel htmlFor="oldPassword">{t('Current password')}</FormLabel>
                <StyledInputText
                  id="oldPassword"
                  type="password"
                  value={old_password}
                  onChange={e => setOldPassword(e.target.value)}
                />
              </FormContent>
            </FormRow>
            <FormRow>
              <FormContent>
                <FormLabel htmlFor="newPassword1">{t('New password')}</FormLabel>
                <StyledInputText
                  id="newPassword1"
                  type="password"
                  value={new_password1}
                  onChange={e => setNewPassword1(e.target.value)}
                />
                <FormInfo>
                  <ul>
                    <li>{t('password-security-info')}</li>
                  </ul>
                </FormInfo>
              </FormContent>
            </FormRow>
            <FormRow>
              <FormContent>
                <FormLabel htmlFor="newPassword2">{t('Repeat new password')}</FormLabel>
                <StyledInputText
                  id="newPassword2"
                  type="password"
                  value={new_password2}
                  onChange={e => setNewPassword2(e.target.value)}
                />
              </FormContent>
              <FormActionButtonGroup>
                <WhiteButtonLink to={`/personal-info`}>{t('Cancel')}</WhiteButtonLink>
                <BlueButton>{t('Change your password')}</BlueButton>
              </FormActionButtonGroup>
            </FormRow>
          </FormContainer>
        </MainBox>
      </LeftSide>
      <RightSide>
        <ContactForm />
      </RightSide>
    </Background>
  )
}

export default PasswordChangeScreen
