import Search from 'Components/Search/Search'
import { useSelector } from 'react-redux'
import { RootState } from '../Store'

const FrontPage = () => {
  const { entities } = useSelector((state: RootState) => state.auth)

  if ('error' in entities) {
    const error = entities.error

    if (error && ('status' in error || 'message' in error)) {
      return <>{'Error ' + error.status + ' - ' + error.message}</>
    }
  }

  if (!('user_guides' in entities)) {
    return null
  }

  return (
    <div className={'contentWrapper'}>
      <Search />
    </div>
  )
}

export default FrontPage
