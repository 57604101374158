import { useEffect, useState } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '../../Store'
import { paymentListMethods } from '../../Features/Payment/paymentSlice'
import { useSelector } from 'react-redux'
import { CheckboxComponent } from '../../Components/SearchResults/InputComponent'
import { Link, useLocation } from 'react-router-dom'
import i18next from 'i18next'

const Background = styled.div`
  background-color: #fff;
  color: #000;
  background-attachment: fixed;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-weight: normal;
  }
`

const Container = styled.div`
  width: 80%;
  display: flex;
`

const ErrorWithPayment = styled.div`
  background-color: #d5dce3;
  padding: 1rem;
  margin-right: 1rem;

  strong {
    color: #2d3264;
  }
`

const LeftSide = styled.div`
  width: 70%;
`
const RightSide = styled.div`
  width: 30%;
  min-width: 10rem;
  background-color: #f3f4f7;
  padding: 1rem;
`

const RightSideTitle = styled.h3`
  align-self: baseline;
  font-size: 1.25rem;
  margin: 0;
`

const ContainerContent = styled.p`
  padding-right: 2.5rem;
`

interface ProviderButton {
  selected: boolean
}

const ProviderButtonContainer = styled.div<ProviderButton>`
  width: 125px;
  height: 125px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  border: ${props => (props.selected ? '5px solid #099bd9' : '1px solid gray')};
  border-radius: 5px;
`

const ProviderButtonImage = styled.img`
  width: 100%;
`

const ContainerTitle = styled.h1`
  text-align: left;
  margin-top: 1rem;
`

const ProviderTitle = styled.h3`
  text-align: left;
  padding: 0;
  padding-top: 1rem;
  margin: 0;
`

const ProviderSubTitle = styled.h3`
  text-align: left;
  padding: 0;
  padding-top: 1rem;
  margin: 0;
`

const ProviderList = styled.div``

const ProviderForm = styled.form`
  padding: 0.25rem;
  float: left;
`

const ProviderSubmit = styled.form`
  text-align: center;
`

const ProviderContainer = styled.div`
  clear: both;
  display: flex;
  flex-direction: column;
`

const Button = styled.button`
  margin: auto;
  border: none;
  padding: 10px 20px;
  border-radius: 20.5px;
  background-color: #099bd9;
  text-align: center;
  color: #fff;
  color: #fff;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  outline: none;
`

const DisabledButton = styled(Button)`
  background-color: #afafaf;
  display: block;
  cursor: normal;
`

const ProviderConfirm = styled.div`
  clear: both;
`

const Table = styled.table`
  width: 100%;
`
const Row = styled.tr``
const Cell = styled.td`
  &:first-child {
    font-weight: bold;
  }
  &:last-child {
    text-align: right;
  }
`

interface Parameters {
  name: string
  value: string
}

interface Provider {
  name: string
  url: string
  svg: string
  group: string
  parameters: any[]
}

const SecondStep = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const queryParams = useLocation().search
  const { entities } = useSelector((state: RootState) => state.payment)

  const settings = useSelector((state: RootState) => state.auth)

  const [selectedPayment, setSelectedPayment] = useState('')
  const [acceptTOS, setAcceptTOS] = useState(false)
  const [errorWithPayment, setErrorWithPayment] = useState(false)

  const serviceTOSlink = settings['entities']['tos_content'][i18next.language]

  const allowPayment = selectedPayment && acceptTOS

  useEffect(() => {
    dispatch(paymentListMethods('FI'))
    if (queryParams) {
      setErrorWithPayment(true)
    }
  }, [dispatch, queryParams])

  const paymentElements = (providers: Provider[], type: string) => {
    return providers
      .filter((provider: Provider) => provider.group === type)
      .map((provider: Provider) => {
        return (
          <ProviderForm>
            <ProviderButtonContainer
              onClick={() => setSelectedPayment(provider.name)}
              selected={selectedPayment === provider.name}
            >
              <ProviderButtonImage src={provider.svg} />
            </ProviderButtonContainer>
          </ProviderForm>
        )
      })
  }

  const bankPayments = paymentElements(entities, 'bank')
  const creditcardPayments = paymentElements(entities, 'creditcard')

  const selectedPaymentElement = (providers: Provider[]) => {
    return providers.map((provider: Provider) => {
      return (
        <ProviderSubmit key={provider.url} method="POST" action={provider.url}>
          {provider.parameters.map((param: Parameters) => {
            return <input type="hidden" name={param.name} value={param.value} />
          })}
          {allowPayment ? (
            <Button>{t('Continue to payment service')}</Button>
          ) : null}
        </ProviderSubmit>
      )
    })
  }

  const selectedPaymentMethod = selectedPaymentElement(
    entities.filter((provider: Provider) => provider.name === selectedPayment)
  )

  const paymentOptions = (
    <>
      <ProviderContainer>
        <ProviderTitle>{t('Payment options')}</ProviderTitle>
        <ContainerContent>
          {t('Choose payment option')}
          <br />
          <a
            target={'_blank'}
            rel="noopener noreferrer"
            href="https://www.checkout.fi/ehdot-ja-sopimukset/maksuehdot"
          >
            {t('Checkout Finland terms')}
          </a>
        </ContainerContent>
        {entities.length > 0 ? (
          <>
            <ProviderList>
              <ProviderSubTitle>{t('Bank options')}</ProviderSubTitle>
              {bankPayments}
            </ProviderList>
            <ProviderList>
              <ProviderSubTitle>{t('Card options')}</ProviderSubTitle>
              {creditcardPayments}
            </ProviderList>
          </>
        ) : (
          <i>{t('Loading payment options')}</i>
        )}
      </ProviderContainer>
    </>
  )

  return (
    <Background>
      <Container>
        <LeftSide>
          <ContainerTitle>{t('Checkout')}</ContainerTitle>
          {errorWithPayment ? (
            <ErrorWithPayment>
              <strong>{t('Error in payment')}</strong>{' '}
              {t('Error in payment desc')}
            </ErrorWithPayment>
          ) : null}
          <ContainerContent>
            <p>{t('Purchase desc')}</p>
            <p>
              {t('Return to frontpage')}{' '}
              <Link to={'/migration-login'}>{t('Here')}</Link>.
            </p>
          </ContainerContent>
          {paymentOptions}
        </LeftSide>
        <RightSide>
          <RightSideTitle>{t('Order summation')}</RightSideTitle>
          <ContainerContent>{t('Service order timeframe')}</ContainerContent>
          <Table>
            <Row>
              <Cell>{t('Price with tax')}</Cell>
              <Cell>
                <strong>30,00 €</strong>
              </Cell>
            </Row>
          </Table>
          <br />
          <CheckboxComponent
            blocked={false}
            onChange={() => setAcceptTOS(!acceptTOS)}
            type={'checkbox'}
            label={
              <a
                target={'_blank'}
                href={serviceTOSlink}
                rel="noopener noreferrer"
              >
                {t('Accept terms of use')}
              </a>
            }
            value={''}
            name={'accept_tos'}
          />
          <br />
          <ProviderConfirm>
            {selectedPaymentMethod}
            {!allowPayment ? (
              <DisabledButton>{t('To selected payment option')}</DisabledButton>
            ) : null}
          </ProviderConfirm>
        </RightSide>
      </Container>
    </Background>
  )
}

export default SecondStep
