import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  text-align: left;
  display: flex;
  margin: 1rem 0;
  flex-direction: initial !important;
`
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
`
const RightSide = styled.div``
const Icon = styled.div`
  font-size: 2rem;
  color: #2d3264;
`
const Bolded = styled.strong``
const Text = styled.div`
  padding-top: 0.5rem;
`

interface LockedFieldsNotification {
  hidden: boolean
}

const LockedFieldsNotificationContainer = (props: LockedFieldsNotification) => {
  const { t } = useTranslation()
  if (!props.hidden) {
    return (
      <Container>
        <LeftSide>
          <Icon>
            <i className="fas fa-lock-alt" />
          </Icon>
        </LeftSide>
        <RightSide>
          <Bolded>{t('Only for subscribed users')}</Bolded>
          <Text>
            {t('Login or signup')}{' '}
            <Link to={`/migration-login`}>{t('Limited to #4')}</Link>.
          </Text>
        </RightSide>
      </Container>
    )
  }
  return null
}

export default LockedFieldsNotificationContainer
