import { useEffect, useState } from 'react'
import {RootState, useAppDispatch} from '../../Store'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Background,
  LeftSide,
  MainBox,
  RightSide,
  MainBoxHeader,
  WhiteButtonLink,
  MainBoxSmallHeader, MainBoxContent, BlueButtonLink
} from "./Components"
import ContactForm from "./ContactForm"
import OrderStatusBox from "./OrderStatusBox"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {fetchCurrentUser} from "../../Features/Auth/authSlice";

const PersonalInfoScreen = () => {
  const queryParams = useLocation().search
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    authed,
    active_subscription,
    expiration_date,
    username,
  } = useSelector((state: RootState) => state.auth)

  const [moveForward, setMoveForward] = useState(false)

  useEffect(() => {
    if (authed && active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/')
    } else if (authed && !active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/expired')
    }
    
    dispatch(fetchCurrentUser())
  }, [authed, active_subscription, moveForward, history, queryParams, dispatch])
  
  /* Payment link is activated after subscription has ended or expiration date is coming up in 30 days. This is
  * to prevent accidental purchases by clients.*/
  const expirationOK = !active_subscription || moment().diff(expiration_date, "days") > -30
  
  return (
    <Background>
      <LeftSide>
        <MainBox>
          <MainBoxHeader>{t('my_information_title')}</MainBoxHeader>
          <OrderStatusBox 
            isActive={active_subscription}
            expirationInfo={expiration_date}
          />

          { expirationOK ? <BlueButtonLink to={`/payment`}>{t('renew_order_action')}</BlueButtonLink>
            : t('renew_guide')}
          
          <MainBoxContent>
            <MainBoxSmallHeader>{t('what_order_includes')}</MainBoxSmallHeader>
            <ul>
              <li>
                {t('order_information')}
              </li>
            </ul>
          </MainBoxContent>
          <hr />
          <MainBoxContent>
            <MainBoxSmallHeader>{t('email_and_password_title')}</MainBoxSmallHeader>
            <MainBoxContent>
              {t('Your current email address is')}: <strong>{ username }</strong>
            </MainBoxContent>
            <p>
              <WhiteButtonLink to={`/email-change`}>{t('Change your email')}</WhiteButtonLink>
              &nbsp;
              <WhiteButtonLink to={`/password-change`}>{t('Change your password')}</WhiteButtonLink>
            </p>
          </MainBoxContent>
        </MainBox>
        <br />
        <WhiteButtonLink to={`/`}>{t('Back to search page')}</WhiteButtonLink>
      </LeftSide>
      <RightSide>
        <ContactForm />
      </RightSide>
    </Background>
  )
}

export default PersonalInfoScreen
