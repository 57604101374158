import i18n from 'i18n'
import logoFiBlue from 'img/siirtolaisuusinstituutti_logo_blue.jpg'
import logoFiWhite from 'img/siirtolaisuusinstituutti_logo_white.png'
import logoSvBlue from 'img/migrationsinstitutet_logo_blue.jpg'
import logoSvWhite from 'img/migrationsinstitutet_logo_white.png'
import logoEnBlue from 'img/migration_institute_of_finland_logo_blue.jpg'
import logoEnWhite from 'img/migration_institute_of_finland_logo_white.png'

type Lang = {
    code: string
    src: {
        logoBlue: string,
        logoWhite: string
    }
    alt: string
}

const DetermineLanguage = () => {

    let lang: Lang = {
        code: '',
        src: {
            logoBlue: '',
            logoWhite: ''
        },
        alt: ''
    }

    switch(i18n.language) {
        case 'en':
            lang = {
                code: 'en',
                src: {logoBlue: logoEnBlue, logoWhite: logoEnWhite},
                alt: 'Logo of Migration institute of Finland'
            }
            break
        case 'sv':
            lang = {
                code: 'sv',
                src: {logoBlue: logoSvBlue, logoWhite: logoSvWhite},
                alt: 'Migrationinstitutets logo'
            }
            break
        default:
            lang = {
                code: 'fi',
                src: {logoBlue: logoFiBlue, logoWhite: logoFiWhite},
                alt: 'Siirtolaisinstituutin logo'
            }
            break
    }

    return lang
}

const LocalisedLogo = DetermineLanguage();

export default LocalisedLogo