import { useEffect } from 'react';
import {logoutUser} from "../../Features/Auth/authSlice";
import {useAppDispatch} from "../../Store";
import {useHistory} from "react-router";
import {resetAction} from "../../Features/Search/searchSlice";

const FirstStep = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(resetAction())
    dispatch(logoutUser()).then(() => history.push('/'))
  }, [dispatch, history])

  return (
    <>
    </>
  )
}

export default FirstStep
