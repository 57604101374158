import { ReactChildren, ReactChild } from 'react'

import { useMediaQuery } from 'react-responsive'

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const Desktop = ({ children }: { children: ReactChildren | ReactChild }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const Tablet = ({ children }: { children: ReactChildren | ReactChild }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}

const Mobile = ({ children }: { children: ReactChildren | ReactChild }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const Default = ({ children }: { children: ReactChildren | ReactChild }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

export { breakpoints, Desktop, Tablet, Mobile, Default }
