import styled from "styled-components";
import {Link} from "react-router-dom";

const Background = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  width: 70%;
  text-align: left;
  padding: 2.5rem;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
  }
`

const BlueButton = styled.button`
  border-radius: 18px;
  border: none;
  background-color: ${props => (props.disabled ? 'gray' : '#2a4b6b')};
  text-align: center;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  line-height: 3rem;
  width: 100%;
`

const WhiteButton = styled(BlueButton)`
  color: #2a4b6b;
  background-color: transparent;
  border: 1px solid #2a4b6b;
`

const WhiteButtonLink = styled(Link)`
  border-radius: 18px;
  border: 1px solid #2a4b6b;
  background-color: transparent;
  text-align: center;
  color: #2a4b6b;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  line-height: 3rem;
  width: 250px;
  text-decoration: none;
  
`

const BlueButtonLink = styled(WhiteButtonLink)`
  background-color: #2a4b6b;
  color: #ffffff;
`

const GrayButtonLink = styled(WhiteButtonLink)`
  background-color: gray;
`

const LeftSide = styled.div`
  width: 70%;
  margin-right: 16px;
  
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`

const RightSide = styled.div`
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 16px;
  }
`

const MainBox = styled.div`
  background-color: #f3f4f7;
  padding: 2.5rem;
  width: 100%;
`

const MainBoxHeader = styled.h1`
  margin: 0;
`

const MainBoxSmallHeader = styled.h3`
  margin: 0;
  font-size: 16px !important;
  line-height: initial !important;
`

const MainBoxContent = styled.div`
  margin: 32px 0;
`

const FormContainer = styled.form`
`
const FormRow = styled.div`
`
const FormLabel = styled.label`
  font-weight: bold;
`

const FormLabelError = styled.div`
  color: red;
  font-size: 1rem;
  margin-bottom: 1rem;
`

const FormContent = styled.div`
  margin: 16px 0;
`
const FormInfo = styled.div`
  font-style: italic;
`

const FormActionButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`

const StyledInputText = styled.input`
    padding-left: 17px;
    height: 36px;
    width: 100%;
    display: block;
    &::placeholder {
      font-style: italic;
    }
`

const FormError = styled.div`
  background-color: #FFF5E2;
  padding: 32px;
  color: #944700;
  margin-bottom: 16px;
`

const FormSuccess = styled.div`
  background-color: #edffe2;
  padding: 32px;
  color: #000000;
  margin-bottom: 16px;
`

const FormSuccessMessage = styled.p`
  p {
    padding-top: 1rem;
  }
`


export { 
  Background, BlueButton, WhiteButton, LeftSide, RightSide, MainBox, FormContent, FormRow, FormLabel, FormContainer,
  FormInfo, WhiteButtonLink, MainBoxHeader, MainBoxSmallHeader, MainBoxContent, BlueButtonLink, StyledInputText,
  FormActionButtonGroup, FormError, FormSuccess, FormLabelError, FormSuccessMessage, GrayButtonLink,
}