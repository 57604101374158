import { useEffect, useState } from 'react'
import { loginUser, registerUser } from '../../Features/Auth/authSlice'
import { RootState, useAppDispatch } from '../../Store'
import { SubscribeServiceLogin } from 'Components/SubscribeService/SubscribeService'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { CheckboxComponent } from '../../Components/SearchResults/InputComponent'
import i18next from 'i18next'

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
`

interface NavigationContainerButtonProps {
  active: boolean
}

const NavigationContainerButton = styled.div<NavigationContainerButtonProps>`
  background-color: ${props => (props.active ? '#22415e' : '#2a4b6b')};
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  height: 3rem;
  display: flex;
  align-items: center;
  > span {
    text-decoration: none;
    min-width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
`

const ErrorWithLogin = styled.div`
  text-align: left;
  padding: 1rem 0;
`

const Background = styled.div`
  background-color: #2a4b6b;
  background: linear-gradient(
    180deg,
    rgba(42, 75, 107, 1) 60%,
    rgba(255, 255, 255, 1) 60%
  );
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const LoginFormContainer = styled.div`
  width: 35rem;
  min-width: 310px;
  border-radius: 6px;
  background-color: #22415e;
  box-shadow: 0 7px 11px 0 rgba(42, 75, 107, 0.5);
  color: #fff;
  margin: 0 50px 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    text-decoration: underline;
  }

  label {
    float: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  form {
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 85%;
  }

  input {
    margin-bottom: 13px;
  }

  @media only screen and (max-width: 768px) {
    width: 75%;
    margin: 0 auto;
  }
`

const StyledTOSLink = styled.a`
  color: #fff;
  font-weight: normal;
  display: block;
  margin-bottom: 1rem;
`

const StyledInputText = styled.input`
  height: 41px;
  padding-left: 17px;
  width: 100%;
  border: 1px solid;
  background-color: #ffffff;
  color: #000;
  outline: none;

  &::placeholder {
    font-style: italic;
  }
`

const LogoContainer = styled.div`
  padding: 1rem;
  img {
    padding-top: 2rem;
    height: 120px;
  }
`

const FormContainer = styled.div`
  width: 90%;
  padding-bottom: 2.5rem;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`

const SubmitButton = styled.button`
  margin: auto;
  border: none;
  padding: 10px 20px;
  border-radius: 20.5px;
  background-color: #099bd9;
  text-align: center;
  color: #fff;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: #afafaf;
    cursor: initial;
  }
`

const PasswordGuideLinesBox = styled.div`
  background-color: #2a4b6b;
  text-align: left;
  font-size: 1.25rem;
  padding: 1rem;
  margin: 1rem 0 2rem 0;

  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-top: 1rem;
  }
`

const LoginPageScreen = () => {
  const dispatch = useAppDispatch()
  const queryParams = useLocation().search
  const history = useHistory()
  const { t } = useTranslation()

  const {
    authed,
    entities,
    loginError,
    registrationError,
    active_subscription
  } = useSelector((state: RootState) => state.auth)

  const [loginUsername, setLoginUsername] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const [registrationUsername, setRegistrationUsername] = useState('')
  const [registrationPassword1, setRegistrationPassword1] = useState('')
  const [registrationPassword2, setRegistrationPassword2] = useState('')
  const [acceptTOS, setAcceptTOS] = useState(false)

  const [moveForward, setMoveForward] = useState(false)

  // Login / registration toggle
  const [showlogin, setShowlogin] = useState(true)

  const submitLogin = () => {
    const username = loginUsername
    const password = loginPassword
    dispatch(loginUser({ username, password })).then(() => setMoveForward(true))
  }

  const submitRegistration = () => {
    const username = registrationUsername
    const password1 = registrationPassword1
    const password2 = registrationPassword2
    dispatch(registerUser({ username, password1, password2 })).then(() =>
      setMoveForward(true)
    )
  }

  useEffect(() => {
    if (authed && active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/')
    } else if (authed && !active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/personal-info')
    }

    /**
     * User can be directed directly to correct form (login or registration) by using queryParams
     */
    if (queryParams === '?registration') setShowlogin(false)
    if (queryParams === '?login') setShowlogin(true)
  }, [authed, active_subscription, moveForward, history, queryParams])

  const serviceTOSlink = entities['tos_content'][i18next.language]

  return (
    <Background>
      <LoginFormContainer>
        <NavigationContainer>
          <NavigationContainerButton
            active={showlogin}
            onClick={() => setShowlogin(true)}
          >
            <span>{t('Login')}</span>
          </NavigationContainerButton>
          <NavigationContainerButton
            active={!showlogin}
            onClick={() => setShowlogin(false)}
          >
            <span>{t('Signup')}</span>
          </NavigationContainerButton>
        </NavigationContainer>
        <LogoContainer>
            <img
              className="logo"
              src={t('logo-href-loginpage')}
              alt="Siirtolaisuusinstituutti logo"
            />
        </LogoContainer>

        {showlogin ? (
          <FormContainer>
            <p>
              {t('Login greeting #1')} {t('Login greeting #2')}
            </p>
            {loginError ? (
              <ErrorWithLogin>
                <strong>{t('Error login')}</strong>
                <br />
                {t('Check input data')}
              </ErrorWithLogin>
            ) : null}
            <form
              action=""
              onSubmit={e => {
                e.preventDefault()
                submitLogin()
              }}
            >
              <label htmlFor="loginUsername">{t('Email')}</label>
              <StyledInputText
                placeholder={t('Type your email')}
                id="loginUsername"
                type="email"
                value={loginUsername}
                onChange={e => setLoginUsername(e.target.value)}
              />
              <br />
              <label htmlFor="loginPassword">{t('Password')}</label>
              <StyledInputText
                placeholder={t('Type a password')}
                id="loginPassword"
                type="password"
                value={loginPassword}
                onChange={e => setLoginPassword(e.target.value)}
              />
              <br />
              <SubmitButton>{t('Log in')}</SubmitButton>
            </form>
            <p>
              <a href={'/' + i18next.language + '/accounts/password_reset/'}>
                {t('Forgot password')}
              </a>
            </p>
          </FormContainer>
        ) : (
          <FormContainer>
            <p>{t('Signup info')}</p>
            {registrationError ? (
              <ErrorWithLogin>
                <strong>{t('Error register')}</strong>{' '}
                {t('User already exists')}
              </ErrorWithLogin>
            ) : null}
            <form
              action=""
              onSubmit={e => {
                e.preventDefault()
                submitRegistration()
              }}
            >
              <label htmlFor="email">{t('Email')}</label>
              <StyledInputText
                placeholder={t('Type your email')}
                id="email"
                type="email"
                value={registrationUsername}
                onChange={e => setRegistrationUsername(e.target.value)}
              />
              <br />
              <label htmlFor="password">{t('Type a password')}</label>
              <StyledInputText
                placeholder={t('Type a password')}
                id="password"
                type="password"
                value={registrationPassword1}
                onChange={e => setRegistrationPassword1(e.target.value)}
              />
              <br />
              <label htmlFor="password_again">{t('Type password again')}</label>
              <StyledInputText
                placeholder={t('Type a password')}
                id="password"
                type="password"
                value={registrationPassword2}
                onChange={e => setRegistrationPassword2(e.target.value)}
              />
              <PasswordGuideLinesBox>
                <strong>{t('Guidelines for password')}</strong>
                <ul>
                  <li>
                    {t(
                      "Your password can't be too similar to your other personal information"
                    )}
                  </li>
                  <li>
                    {t('Your password must contain at least 8 characters.')}
                  </li>
                  <li>
                    {t("Your password can't be a commonly used password.")}
                  </li>
                  <li>{t("Your password can't be entirely numeric.")}</li>
                </ul>
              </PasswordGuideLinesBox>
              <CheckboxComponent
                blocked={false}
                onChange={() => setAcceptTOS(!acceptTOS)}
                type={'checkbox'}
                label={
                  <StyledTOSLink
                    target={'_blank'}
                    href={serviceTOSlink}
                    rel="noopener noreferrer"
                  >
                    {t('Accept terms of use')}
                  </StyledTOSLink>
                }
                value={''}
                name={'accept_tos'}
              />
              <br />
              <SubmitButton disabled={!acceptTOS}>
                {t('Confirm and direct to payment')}
              </SubmitButton>
            </form>
          </FormContainer>
        )}
      </LoginFormContainer>
      <SubscribeServiceLogin />
    </Background>
  )
}

export default LoginPageScreen
