import { useEffect } from 'react'
import styles from './SingleSearchResult.module.scss'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Row, Col, Container } from 'Components/Layout/Grid'
import SingleSuggestedResult from 'Components/SearchResults/SingleSuggestedResult'
import { useTranslation } from 'react-i18next'
import FetchedDataContainer from './FetchedDataContainer'
import LocalisedLogo from 'Util/LocalisedLogo'
import styled from 'styled-components'

/* eslint-disable */
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
/* eslint-enable */

import {
  searchById,
  singleSearchResponse,
  RegistryTypes,
  searchSuggestedResults,
  RegistryEntry
} from 'Features/Search/searchSlice'
import SingleSuggestedReferenceResult from 'Components/SearchResults/SingleSuggestedReferenceResult'
import ResultContactForm from "./ResultContactForm";

interface PageParams {
  id: string
  registry: RegistryTypes
}

const LeftSide = styled.div`
  width: 60%;
  padding-right: 32px;

  @media only screen and (max-width: 992px) {
  padding-right: 0;
    width: 100%;
  }
  
  @media print {
    width: 100%;
    page-break-after: always;
  }
`

const ResultContactContainer = styled.div`
  margin-top: 32px;
  
  @media print {
    display: none;
  }
`

const RightSide = styled.div`
  width: 40%;
  text-align: left;
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  @media print {
    width: 100%;
  }
`

const ReferenceText = styled.div``

const LegendInformation = styled.div`
  text-align: left;
  margin-top: 16px;
  @media only screen and (max-width: 1200px) {
  }
  
  table {
    margin-top: 4px;
    border-collapse: collapse;
    width: 100%;
  }
  
  td {
    padding: 0 4px;
  }
`

const RegisterTitle = styled.h3`
`

const BackToSearchResults = styled.div`
  margin-bottom: 32px;
`

const ReferenceTitle = styled.div`
  @media print {
    margin-top: 16px;
  }
`

const SuggestedResults = styled.div`
  margin-bottom: 16px;
  @media print {
    display: none;
  }
`

const SingleSearchResult = () => {
  const { t, i18n } = useTranslation()
  const { id, registry } = useParams<PageParams>()
  const queryParams = useLocation().search
  const { singleResult, suggestedResults } = useSelector(
    (state: RootState) => state.search
  )
  const dispatch = useAppDispatch()
  let displayData

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(
      searchById({
        registry,
        id: id as unknown as number
      })
    )
  }, [dispatch, id, registry])

  useEffect(() => {
    const idNumber = Number.parseInt(id) as number

    dispatch(
      searchSuggestedResults({
        currentId: idNumber,
        first_names: singleResult[idNumber]?.first_names,
        last_name: singleResult[idNumber]?.last_name,
        date_of_birth: singleResult[idNumber]?.date_of_birth,
        age: singleResult[idNumber]?.age,
        registry: registry,
        date_of_departure: singleResult[idNumber]?.date_of_departure,
        issued_date: singleResult[idNumber]?.issued_date
      })
    )
  }, [dispatch, id, registry, singleResult])

  let payload: singleSearchResponse | null = null
  if (singleResult) {
    payload = singleResult[id as unknown as number]
  }

  const commonData = [
    {
      fieldName: t('Last name'),
      fetchedData: payload?.last_name
    },
    {
      fieldName: t('first_names'),
      fetchedData: payload?.first_names
    }
  ]

  const fields = {
    passenger_list: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('age_group'),
            fetchedData: payload?.age
          },
          {
            fieldName: t('place_of_departure'),
            fetchedData: payload?.place_of_departure
          },
          {
            fieldName: t('destination'),
            fetchedData: payload?.destination
          },
          {
            fieldName: t('destination_state'),
            fetchedData: payload?.destination_state
          },
          {
            fieldName: t('destination_country'),
            fetchedData: payload?.destination_country
          },
          {
            fieldName: t('price'),
            fetchedData: payload?.price
          },
          {
            fieldName: t('ship'),
            fetchedData: payload?.ship
          },
          {
            fieldName: t('date_of_departure'),
            fetchedData: payload?.date_of_departure
          },
          {
            fieldName: t('ship_e'),
            fetchedData: payload?.ship_e
          },
          {
            fieldName: t('departure_e'),
            fetchedData: payload?.departure_e
          },
          {
            fieldName: t('line'),
            fetchedData: payload?.line
          },
          {
            fieldName: t('port_of_departure'),
            fetchedData: payload?.port_of_departure
          },
          {
            fieldName: t('list_number'),
            fetchedData: payload?.list_number
          },
          {
            fieldName: t('note'),
            fetchedData: payload?.note
          },
          {
            fieldName: t('gender'),
            fetchedData: payload?.gender
          },
          {
            fieldName: t('route'),
            fetchedData: payload?.route
          }
        ]
      }
    ],
    australian_finns: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('nationality_date'),
            fetchedData: payload?.nationality
          },
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('birthplace'),
            fetchedData: payload?.birthplace
          },
          {
            fieldName: t('birth_province'),
            fetchedData: payload?.birth_province
          },
          {
            fieldName: t('arrival_time_australia'),
            fetchedData: payload?.arrival_time
          },
          {
            fieldName: t('marital_status'),
            fetchedData: payload?.marital_status
          },
          {
            fieldName: t('spouse'),
            fetchedData: payload?.spouse
          },
          {
            fieldName: t('australian_address'),
            fetchedData: payload?.australian_address
          },
          {
            fieldName: t('australian_profession'),
            fetchedData: payload?.australian_profession
          },
          {
            fieldName: t('proficiency_in_english'),
            fetchedData: payload?.proficiency_in_english
          },
          {
            fieldName: t('itinerary'),
            fetchedData: payload?.itinerary
          },
          {
            fieldName: t('australian_residences'),
            fetchedData: payload?.australian_residences
          },
          {
            fieldName: t('matrikk_no'),
            fetchedData: payload?.matrikk_no
          },
          {
            fieldName: t('konsul_tieto'),
            fetchedData: payload?.konsul_tieto
          },
          {
            fieldName: t('profession'),
            fetchedData: payload?.profession
          },
          {
            fieldName: t('passport_date'),
            fetchedData: payload?.passport_date
          },
          {
            fieldName: t('other_passports'),
            fetchedData: payload?.other_passports
          },
          {
            fieldName: t('sho_id'),
            fetchedData: payload?.sho_id
          },
          {
            fieldName: t('date_of_departure'),
            fetchedData: payload?.date_of_departure
          },
          { fieldName: t('age_group'), fetchedData: payload?.age },
          {
            fieldName: t('route_price'),
            fetchedData: payload?.route_price
          },
          {
            fieldName: t('other_tickets'),
            fetchedData: payload?.other_tickets
          },
          {
            fieldName: t('ska_information'),
            fetchedData: payload?.ska_information
          },
          {
            fieldName: t('swedish_archival_information'),
            fetchedData: payload?.swedish_archival_information
          },
          {
            fieldName: t('additional_information'),
            fetchedData: payload?.additional_information
          }
        ]
      }
    ],
    kaski_finns: [
      {
        commonData: [],
        fieldData: [
          {
            fieldName: t('Last name'),
            fetchedData: payload?.last_name
          },
          {
            fieldName: t('first_name'),
            fetchedData: payload?.first_names
          },
          {
            fieldName: t('middle_name'),
            fetchedData: payload?.middle_name
          },
          {
            fieldName: t('resident_in_finland'),
            fetchedData: payload?.resident_in_finland
          },
          {
            fieldName: t('migration'),
            fetchedData: payload?.migration
          },
          {
            fieldName: t('residential_village_in_finland'),
            fetchedData: payload?.residential_village_in_finland
          },
          {
            fieldName: t('resident_in_sweden'),
            fetchedData: payload?.resident_in_sweden
          },
          {
            fieldName: t('residential_village_in_sweden'),
            fetchedData: payload?.residential_village_in_sweden
          },
          {
            fieldName: t('other_migratory_destination'),
            fetchedData: payload?.other_migratory_destination
          },
          {
            fieldName: t('sources'),
            fetchedData: payload?.sources
          }
        ]
      }
    ],
    leitzinger_naturalizations: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('profession'),
            fetchedData: payload?.profession
          },
          {
            fieldName: t('decision_date'),
            fetchedData: payload?.decision_date
          },
          {
            fieldName: t('family'),
            fetchedData: payload?.family
          },
          {
            fieldName: t('nationality'),
            fetchedData: payload?.nationality
          },
          {
            fieldName: t('oath_date'),
            fetchedData: payload?.oath_date
          },
          {
            fieldName: t('place'),
            fetchedData: payload?.place
          },
          {
            fieldName: t('additional_information'),
            fetchedData: payload?.additional_information
          }
        ]
      }
    ],
    new_zealand_finns: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('parish'),
            fetchedData: payload?.parish
          },
          {
            fieldName: t('arrival_time_to_nz'),
            fetchedData: payload?.arrival_time
          },
          {
            fieldName: t('date_of_naturalization'),
            fetchedData: payload?.date_of_naturalization
          },
          {
            fieldName: t('residence_in_nz'),
            fetchedData: payload?.residence
          },
          {
            fieldName: t('occupied'),
            fetchedData: payload?.occupied
          },
          {
            fieldName: t('date_of_death'),
            fetchedData: payload?.date_of_death
          },
          {
            fieldName: t('notes_nz'),
            fetchedData: payload?.notes
          }
        ]
      }
    ],
    russian_finnish_persecution_victims: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('Birth place'),
            fetchedData: payload?.birthplace
          },
          {
            fieldName: t('additional_information'),
            fetchedData: payload?.memo
          },
          {
            fieldName: t('source'),
            fetchedData: payload?.source
          }
        ]
      }
    ],
    martyrology_of_ingrian_finns: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('Birth place'),
            fetchedData: payload?.birthplace
          },
          {
            fieldName: t('additional_information'),
            fetchedData: payload?.memo
          },
          {
            fieldName: t('source'),
            fetchedData: payload?.source
          }
        ]
      }
    ],
    passport_list: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('marital_status'),
            fetchedData: payload?.marital_status
          },
          {
            fieldName: t('religion'),
            fetchedData: payload?.religion
          },
          {
            fieldName: t('profession'),
            fetchedData: payload?.profession
          },
          {
            fieldName: t('home_region'),
            fetchedData: payload?.home_region
          },
          {
            fieldName: t('province'),
            fetchedData: payload?.residence
          },
          {
            fieldName: t('issued_date'),
            fetchedData: payload?.issued_date
          },
          {
            fieldName: t('passport_number'),
            fetchedData: payload?.passport_number
          },
          {
            fieldName: t('duration'),
            fetchedData: payload?.duration
          },
          {
            fieldName: t('destination_country'),
            fetchedData: payload?.destination_country
          },
          {
            fieldName: t('passport_issuer'),
            fetchedData: payload?.passport_issuer
          },
          {
            fieldName: t('note'),
            fetchedData: payload?.note
          },
          {
            fieldName: t('mikrof_nr'),
            fetchedData: payload?.mikrof_nr
          },
          {
            fieldName: t('family'),
            fetchedData: payload?.family
          },
          {
            fieldName: t('gender'),
            fetchedData: payload?.gender
          }
        ]
      }
    ],
    reference_database: [
      {
        commonData,
        fieldData: [
          {
            fieldName: t('alias'),
            fetchedData: payload?.alias
          },
          {
            fieldName: t('date_of_birth'),
            fetchedData: payload?.date_of_birth
          },
          {
            fieldName: t('birthplace'),
            fetchedData: payload?.birthplace
          },
          {
            fieldName: t('state_of_birth'),
            fetchedData: payload?.state_of_birth
          },
          {
            fieldName: t('country_of_birth'),
            fetchedData: payload?.country_of_birth
          },
          {
            fieldName: t('time_of_death'),
            fetchedData: payload?.time_of_death
          },
          {
            fieldName: t('state_of_death'),
            fetchedData: payload?.state_of_death
          },
          {
            fieldName: t('country_of_death'),
            fetchedData: payload?.country_of_death
          },
          {
            fieldName: t('references'),
            fetchedData: payload?.references
          },
          {
            fieldName: t('note'),
            fetchedData: payload?.note
          }
        ]
      }
    ]
  }

  for (const [key, value] of Object.entries(fields)) {
    if (key === registry) {
      displayData = value
    }
  }

  // Get translated legend field
  const referenceFieldName = `reference_to_a_source_${i18n.language}`
  const legendFieldName = `registry_legend_${i18n.language}`

  // Current year for copyright in print view
  const currentYear = new Date().getFullYear()

  // Suggested result containers
  const suggestedResultContainers = [] as any
  if (registry === 'martyrology_of_ingrian_finns') {
    if (Array.isArray(payload?.all_references)) {
      const suggestedResults = payload?.all_references.map(
        (item: Partial<RegistryEntry>) => {
          return (
            <SingleSuggestedReferenceResult
              last_name={item.last_name}
              first_names={item.first_names}
              registryName={item.registryName}
              registry={registry}
              id={item.id}
            />
          )
        }
      )
      suggestedResultContainers.push(suggestedResults)
    }
  } else {
    for (const [key, value] of Object.entries(suggestedResults)) {
      value.forEach(registry => {
        suggestedResultContainers.push(
          <SingleSuggestedResult
            key={key + registry.id}
            registryName={key}
            registry={registry}
          />
        )
      })
    }
  }

  return (
    <div className={styles.outermostContainer}>
      <Container>
        <Row>
          <LeftSide>
            <div className={styles.actions}>
              <div>
                <BackToSearchResults>
                  <Link to={`/${queryParams}`}>
                    &larr; {t('Return to search results')}
                  </Link>
                </BackToSearchResults>
              </div>
              <div>
                <span onClick={() => window.print()}>
                  {t('Print')} &nbsp;
                  <i className="fa fa-print" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div className={styles.containerLogoName}>
              <RegisterTitle>
                {t(`${registry}`)}
              </RegisterTitle>
              <div>
                <img src={LocalisedLogo.src.logoBlue} alt={LocalisedLogo.alt} />
              </div>
            </div>
            {displayData?.map((item, index) => {
              const firstNames = payload?.first_names || ''
              const lastName = payload?.last_name || ''

              return (
                <Col key={index} size={12}>
                  <h3>{`${firstNames} ${lastName}`}</h3>
                  <ul>
                    {item.commonData.map((innerItem, innerIndex) => {
                      return (
                        <FetchedDataContainer
                          data={innerItem}
                          key={innerIndex}
                        />
                      )
                    })}
                    {item.fieldData.map((innerItem, innerIndex) => {
                      return (
                        <FetchedDataContainer
                          data={innerItem}
                          key={innerIndex}
                        />
                      )
                    })}
                  </ul>
                </Col>
              )
            })}
            <ResultContactContainer>
              <ResultContactForm data={payload} registry={t(`${registry}`)} />
            </ResultContactContainer>
          </LeftSide>
          <RightSide>
            { registry === "passenger_list" || registry === "passport_list" ?
              <SuggestedResults>
                <h3>{t('suggested_results_from_other_registries')}</h3>
                {suggestedResultContainers.length > 0
                  ? suggestedResultContainers
                  : t('no_suggested_results')}
              </SuggestedResults>
            : null}
            <div>
              <ReferenceTitle>
                <h3>{t('Original reference')}</h3>
              </ReferenceTitle>
              <ReferenceText>
                {
                  <div
                    dangerouslySetInnerHTML={{
                      __html: payload?.registry_information![referenceFieldName]
                    }}
                  />
                }
              </ReferenceText>
            </div>
            <LegendInformation>
              <ReferenceTitle>
                <h3>{t('Käytetyt lyhenteet')}</h3>
              </ReferenceTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html: payload?.registry_information![legendFieldName]
                }}
              />
            </LegendInformation>
          </RightSide>
        </Row>
      </Container>
      <span className={styles.copyright}>
        © Copyright Siirtolaisuusinstituutti {currentYear}
      </span>
    </div>
  )
}

export default SingleSearchResult
