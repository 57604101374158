import styled from 'styled-components'
import { RegistryTypes } from '../../Features/Search/searchSlice'
import {Autocomplete} from "@mui/material"

interface BlockedProps {
  blocked?: boolean
}
const InputContainer = styled.div<BlockedProps>``
const CheckboxContainer = styled.div<BlockedProps>`
  display: flex;
  align-items: center;
`
const InputLabel = styled.label<BlockedProps>`
  color: ${props => (props.blocked ? '#AFAFAF' : 'initial')};
`

const CheckboxLabel = styled(InputLabel)``

const Input = styled.input<BlockedProps>`
  border: ${props =>
    props.blocked ? '1px solid #afafaf;' : '1px solid #bfcde0;'};
  outline: none;
`

const Checkbox = styled(Input)`
  cursor: pointer;
  text-align: left;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 1rem;
  &:checked {
    background-color: #183e6f;
    color: #fff;
  }
`

interface InputComponent {
  label: string | any
  blocked?: boolean
  type: string
  placeholder?: string
  value: any | string | number | RegistryTypes[]
  name: string
  onChange: any
  checked?: boolean
  options?: any
}

const InputComponentContainer = (props: InputComponent) => {
  let inputComponent = <Input
        blocked={props.blocked}
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        disabled={props.blocked}
        minLength={2}
        id=""
        value={props.value}
        name={props.name}
      />
  
  if (props.type === "autocomplete" && !props.blocked) {
    inputComponent = <Autocomplete
      disablePortal
      freeSolo
      options={props.options}
      filterOptions={(options, state) => {
        if (state.inputValue.length >= 3) {
          return options.filter((item: any) =>
            String(item).toLowerCase().startsWith(state.inputValue.toLowerCase())
          )
        }
        return []
      }}
      sx={{ width: '100%' }}
      size={"small"}
      value={props.value}
      onChange={(event, newValue, fieldName) => props.onChange(event, newValue, props.name)}
      inputValue={props.value}
      onInputChange={props.onChange}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <Input 
            type="text" 
            name={props.name}
            blocked={props.blocked}
            placeholder={props.placeholder}
            disabled={props.blocked}
            {...params.inputProps}
          />
        </div>
      )}
    />
  }
  
  return (
    <InputContainer>
      <InputLabel blocked={props.blocked}>{props.label}</InputLabel>
      {inputComponent}
    </InputContainer>
  )
}

const CheckboxComponent = (props: InputComponent) => {
  return (
    <CheckboxContainer>
      <Checkbox
        blocked={props.blocked}
        onChange={props.onChange}
        type={props.type}
        checked={props.checked}
        placeholder={props.placeholder}
        disabled={props.blocked}
        id=""
        value={props.value}
        name={props.name}
      />
      <CheckboxLabel blocked={props.blocked}>{props.label}</CheckboxLabel>
    </CheckboxContainer>
  )
}

export { InputComponentContainer as InputComponent, CheckboxComponent }
