import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import TokenUtil from 'Util/tokenManager'

/* SIIRTOLAISREKISTERI API */

const axiosOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

const getAxiosAPIInstance = (): AxiosInstance => {
  if (axiosOptions.headers) {
    axiosOptions.headers.Authorization = TokenUtil.isEmpty()
      ? ''
      : `Token ${TokenUtil.get()}`
  }

  const instance = axios.create(axiosOptions)

  return instance
}

/* SIIRTOLAISUUSINSTITUUTTI WP API */

const axiosWPOptions: AxiosRequestConfig = {
  baseURL:
    'https://siirtolaisuusinstituutti.fi/wp-json/siirtolaisuusinstituutti/v1',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

const getAxiosWPInstance = (): AxiosInstance => {
  return axios.create(axiosWPOptions)
}

export { getAxiosAPIInstance, getAxiosWPInstance }
