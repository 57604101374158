import styles from './SearchResult.module.scss'
import { useTranslation } from 'react-i18next'
import {DataGridPro, GridColDef, GridEventListener} from '@mui/x-data-grid-pro'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'Store'
import {
  changePage,
  RegistryEntry,
  setRegistryFilter
} from 'Features/Search/searchSlice'
import styled from 'styled-components'
import GuideboxesScreen from "../Search/Guidebox"
import moment from "moment"


type Results = {
  registryName: string
  registry: Partial<RegistryEntry>
}

const Container = styled.div`
  width: 100%;
  top: 0;
  margin: 0 auto;
  padding-top: 15px;
  text-align: left;
  @media only screen and (min-width: 768px) {
    margin: 0 auto auto 50px;
    padding-top: 0;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    padding: 15px;
    margin: 0;
  }
  > div {
    padding-right: 4px;
  }
`

const PendingForResults = styled.div`
  padding: 1rem;
  text-align: center;
  font-weight: bold;
`

const ResultCounter = styled.div`
  font-size: 1.35rem;
  color: #2d3264;
  margin-bottom: 1rem;
`

interface ResultPillSingle {
  active: boolean
}

const ResultPillContainer = styled.div``
const ResultPillSingleContainer = styled.span<ResultPillSingle>`
  background-color: ${props => (props.active ? '#304B6A' : '#f3f4f7')};
  color: ${props => (props.active ? '#FFF' : '#000')};
  border: 1px solid #BFCDE0;
  border-radius: 36px;
  font-size: 1.25rem;
  margin-bottom: 4px;
  margin-right: 4px;
  display: inline-block;
  padding: 4px 8px;
  line-height: 1.75rem;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => (props.active ? '#304B6A' : '#ffffff')};
  }
  
`

const countOfResults = (arr: any, val: string) =>
  arr.reduce((a: any, v: any) => (v.registryName === val ? a + 1 : a), 0)


const SearchResult = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  
  const orderValueGetter: any = (value: string) => { 
    if (value) {
      const date = moment(value, "DD.MM.YYYY", true)
      if (date.isValid()) {
        return date.format("DD.MM.YYYY")
      }
    }
    return value
  }
  
  const orderValueFormatter: any = (value: string) => {
    return value
  }
  
  const fieldMapper: { [index: string]: GridColDef[] } = {
    passenger_list: [
        { field: 'last_name', headerName: t('last_name'), flex: 1 },
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { field: 'destination', headerName: t('destination'), flex: 1},
        { field: 'destination_country', headerName: t('destination_country'), flex: 1},
        { field: 'destination_state', headerName: t('destination_state'), flex: 1},
        { 
          field: 'date_of_departure', 
          headerName: t('date_of_departure'), 
          flex: 1,
          valueGetter: orderValueGetter,
          valueFormatter: orderValueFormatter,
        },
    ],
    passport_list: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { 
          field: 'issued_date', 
          headerName: t('issued_date'), 
          flex: 1,
          valueGetter: orderValueGetter,
          valueFormatter: orderValueFormatter,
        },
        { 
          field: 'date_of_birth', 
          headerName: t('date_of_birth'), 
          flex: 1,
          valueGetter: orderValueGetter,
          valueFormatter: orderValueFormatter,
        },
        { field: 'home_region', headerName: t('home_region'), flex: 1},
        { field: 'destination_country', headerName: t('destination_country'), flex: 1},
    ],
    reference_database: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { field: 'alias', headerName: t('alias'), flex: 1},
        { field: 'birthplace', headerName: t('birthplace'), flex: 1},
        { field: 'country_of_birth', headerName: t('country_of_birth'), flex: 1},
    ],
    australian_finns: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { field: 'date_of_birth', headerName: t('date_of_birth'), flex: 1},
        { field: 'birthplace', headerName: t('birthplace'), flex: 1},
        { field: 'arrival_time', headerName: t('arrival_time'), flex: 1},
    ],
    new_zealand_finns: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { field: 'date_of_birth', headerName: t('date_of_birth'), flex: 1},
        { field: 'parish', headerName: t('parish'), flex: 1},
        { field: 'arrival_time', headerName: t('arrival_time'), flex: 1},
    ],
    leitzinger_naturalizations: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
        { field: 'nationality', headerName: t('nationality'), flex: 1},
        { field: 'oath_date', headerName: t('oath_date'), flex: 1},
    ],
    kaski_finns: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
    ],
    russian_finnish_persecution_victims: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
    ],    
    martyrology_of_ingrian_finns: [
        { field: 'last_name', headerName: t('last_name'), flex: 1},
        { field: 'first_names', headerName: t('first_names'), flex: 1},
    ],
  }
  
  const history = useHistory()
  const {
    search: {
      entities,
      registryFilter,
      searchSettings,
      loading,
      error
    }
  } = useSelector((state: RootState) => state)

  const handleShowRegistry = (value: string) => {
    dispatch(setRegistryFilter(value === registryFilter ? '' : value))
  }
  
  if (loading === 'pending') {
    return (
      <Container>
        <PendingForResults>{t('Looking for results...')}</PendingForResults>
      </Container>
    )
  }

  const registries: string[] = []
  const queryResults: Results[] = []

  for (const [key, value] of Object.entries(entities)) {
    if (value.length > 0) {
      registries.push(key)
    }
    value.forEach(registry => {
      queryResults.push({ registryName: key, registry })
    })
  }

  /* By default select first registry from the registries list as only one registry can be selected at once. */
  if (!registryFilter) {
    dispatch(setRegistryFilter(registries[0]))
  }
  
  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    history.push(`${registryFilter}/${params.id}/`)
  }
  
  const queryResultsFiltered = queryResults.filter(item => {
    return registryFilter ? registryFilter === item.registryName : true
  })
  
  /* Column titles */
  const columns: GridColDef[] = (registryFilter ? fieldMapper[registryFilter] : [])
  
  /* Result rows */
  const results = queryResultsFiltered.map(item => {
    const key = item.registry.id
    const row: { [key: string]: any } = {}
    row['id'] = key
    
    columns.forEach(column => {
      const registry: any = { ...item.registry }
      const accesskey = column.field
      row[accesskey] = registry[accesskey]
    });

    return row
  })

  if (error && ('status' in error || 'message' in error)) {
    return (
      <Container>{'Error ' + error.status + ' - ' + error.message}</Container>
    )
  }

  return (
    <Container>
      {queryResults.length <= 0 ? (
          <GuideboxesScreen />
      ) : (
        <>
          <ResultCounter>
            {`${queryResults.length} ${t('Corresponding results')}`}
          </ResultCounter>
          {
            <ResultPillContainer>
              {registries.map(item => {
                return (
                  <ResultPillSingleContainer
                    onClick={() => {
                      handleShowRegistry(item)
                    }}
                    key={item}
                    className={styles.pill}
                    active={registryFilter === item}
                  >
                    {t(`${item}`)} {countOfResults(queryResults, item)}{' '}
                    {t('pcs')}
                  </ResultPillSingleContainer>
                )
              })}
            </ResultPillContainer>
          }
          <div>
            <DataGridPro
              autoHeight
              onRowClick={handleEvent}
              rows={results}
              columns={columns}
              pagination
              disableColumnFilter
              disableColumnReorder
              disableColumnMenu
              onPaginationModelChange={(a) => dispatch(changePage(a))}
              initialState={{
                pagination: {
                  paginationModel: searchSettings,
                }
              }}
              pageSizeOptions={[50, 100, 1000]}
              sx={{
                  '&.MuiDataGrid-root': {
                    border: 'none',
                    fontSize: '1.25rem'
                  },
                  // disable cell selection style
                  '.MuiDataGrid-cell:focus': {
                    outline: 'none'
                  },
                  // pointer cursor on ALL rows
                  '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                  }
              }}
            />
          </div>
          <br />
        </>
      )}
    </Container>
  )
}

export default SearchResult
