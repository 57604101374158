import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SingleResult } from '../../Features/Search/searchSlice'
import { getFields } from './SingleResult'

const Container = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1rem;
`
const Title = styled.span`
  font-weight: bold;
  font-size: 1.35rem !important;
  line-height: 1.35rem;
  margin: 0;
  padding-bottom: 0rem !important;
`
const Content = styled.div`
  font-size: 1.35rem;

  a {
    margin-top: 0.25rem;
    text-decoration: none;
    display: block;
    color: #41a2db;
  }

  a:visited {
    color: #41a2db;
  }

  a:hover {
    text-decoration: underline;
  }
`

const SingleSuggestedResult = (props: SingleResult) => {
  const { t } = useTranslation()
  const fieldMapper: { [index: string]: { [index: string]: string } } = {
    passenger_list: {
      date_of_departure: t('date_of_departure')
    },
    passport_list: {
      issued_date: t('passport_date'),
      date_of_birth: t('date_of_birth')
    }
  }
  const queryParams = useLocation().search
  const { registry, registryName } = props
  const id = registry['id'] || null
  const firstName = registry['first_names'] || ''
  const lastName = registry['last_name'] || ''
  const fields = getFields(fieldMapper, registryName, registry)

  return (
    <Container>
      <Title>{`${firstName} ${lastName}`}</Title>
      <Content>
        {t(`${registryName}`)}
        {fields}
        <Link to={`/${registryName}/${id}/${queryParams}`} replace>
          {t('Show detailed view')} &#10095;
        </Link>
      </Content>
    </Container>
  )
}

export default SingleSuggestedResult
