import { useEffect } from 'react'
import { useAppDispatch } from '../../Store'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { paymentSuccess } from '../../Features/Payment/paymentSlice'

const Background = styled.div`
  background-color: #fff;
  color: #000;
  background-attachment: fixed;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  width: 80%;
`

const ContainerContent = styled.p``
const ContainerTitle = styled.h1`
  text-align: left;
`

const CallToActionContainer = styled.div``

const Button = styled.button`
  margin: auto;
  border: none;
  padding: 10px 20px;
  border-radius: 20.5px;
  background-color: #099bd9;
  text-align: center;
  color: #fff;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
`

const Success = () => {
  const queryParams = useLocation().search
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(paymentSuccess(queryParams))
  }, [dispatch, queryParams])

  return (
    <Background>
      <Container>
        <ContainerTitle>{t('Thanks for ordering')}</ContainerTitle>
        <ContainerContent>{t('All features')}</ContainerContent>
        <CallToActionContainer>
          <Button onClick={() => history.push('/')}>
            {t('Continue to the service')}
          </Button>
        </CallToActionContainer>
      </Container>
    </Background>
  )
}

export default Success
