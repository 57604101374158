import i18n from 'i18next'
import fi from './locales/fi/translation.json'
import en from './locales/en/translation.json'
import sv from './locales/sv/translation.json'
import { initReactI18next } from 'react-i18next'

/* row 3 through 14 are form fields */
/* row 15 through 21 are placholders for form fields */
/* row 37 through 101 are RegistryEntry fields */
/* row 104 and 105 single search results */
/* row 106 through XXX login / signup page info */
// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {fi, sv, en},
    lng: 'fi',
    supportedLngs: ['fi', 'sv', 'en'], 

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;