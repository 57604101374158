import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction
} from '@reduxjs/toolkit'

import {getAxiosAPIInstance} from 'Api'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>
/* eslint-disable */
interface Payment {
  language?: string
}
/* eslint-enable */

interface AuthState {
  entities: []
  success: boolean,
  tosContent: string,
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
}

interface Parameters {
  name: string,
  value?: string,
}

export interface Provider {
  url: string,
  icon: string,
  svg: string,
  name: string,
  group: string,
  id: string,
  parameters: Partial<Parameters>[]
}

const initialState: AuthState = {
  entities: [],
  success: true,
  tosContent: '',
  loading: 'idle',
}

const paymentListMethods = createAsyncThunk(
  'payment/methods',
    async (language: string) => {
      const response = await getAxiosAPIInstance().post(`/checkout/payment-create-session/`, {
        language
      })
      return response.data
    }
)

const paymentSuccess = createAsyncThunk(
  'payment/success',
  async (query: string) => {
    const response = await getAxiosAPIInstance().get(`/checkout/payment-callback-success/${query}`)
    return response.data
  }
)

const REDUCER_NAME = 'payment'

const isPendingAction = (action: AnyAction): action is PendingAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/pending')
  )
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/rejected')
  )
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/fulfilled')
  )
}

export const resetAction = createAction('reset-tracked-loading-state')

const paymentSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: builder => {
    builder
      .addCase(resetAction, () => initialState)
      .addCase(paymentListMethods.fulfilled, (state, { payload }) => {
        state.entities = payload
      })
      .addCase(paymentListMethods.rejected, (state) => {
        state.entities = []
      })
      .addCase(paymentSuccess.fulfilled, (state, { payload }) => {
        state.success = true
      })
      .addCase(paymentSuccess.rejected, (state, { payload }) => {
        state.success = false
      })      
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, (state) => {
        state.loading = 'fulfilled'
      })
  }
})

export default paymentSlice.reducer
export { paymentListMethods, paymentSuccess }
