import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom'
import TokenUtil from "./tokenManager";

interface Props extends RouteProps {
  component: React.ComponentType
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  let isAuthenticated = false

  let redirect = false

  const token = TokenUtil.get()
  if (token !== null) {
    isAuthenticated = true
  }
  
  return (
    <Route
      render={props =>
        !isAuthenticated || redirect ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...rest} />
        )
      }
      {...rest}
    />
  )
}

export default PrivateRoute
