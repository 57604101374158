import {FC, useEffect, useState} from 'react'
import { RootState, useAppDispatch } from '../../Store'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Background,
  BlueButton, FormActionButtonGroup,
  FormContainer, FormContent,
  FormRow,
  LeftSide,
  MainBox, MainBoxHeader,
  RightSide, StyledInputText,
  WhiteButtonLink, FormError, FormSuccess,
} from "./Components";
import {changeEmailUser} from "../../Features/Auth/authSlice";
import ContactForm from "./ContactForm";

const EmailChangeScreen: FC = () => {
  const dispatch = useAppDispatch()
  const queryParams = useLocation().search
  const history = useHistory()
  const { t } = useTranslation()

  const {
    authed,
    active_subscription
  } = useSelector((state: RootState) => state.auth)

  const [moveForward, setMoveForward] = useState(false)
  const [password, setPassword] = useState('')
  const [new_username, setNewUsername] = useState('')
  const [errorHappened, setErrorHappened] = useState(false)
  const [successHappened, setSuccessHappened] = useState(false)
  
  const changePassword = () => {
    dispatch(
      changeEmailUser({ 
        password,
        new_username,
      })
    ).then((response) => {
      if ("error" in response) {
        setErrorHappened(true)
      } else {
        setSuccessHappened(true)
      }
    })
  }
  
  useEffect(() => {
    if (authed && active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/personal-info')
    } else if (authed && !active_subscription && moveForward) {
      setMoveForward(false)
      history.push('/expired')
    }
    
    if (successHappened) {
      const toRef = setTimeout(() => {
        setMoveForward(true)
        clearTimeout(toRef)
      }, 3000)
    }

    /**
     * User can be directed directly to correct form (login or registration) by using queryParams
     */
  }, [authed, active_subscription, moveForward, errorHappened, successHappened, history, queryParams])

  return (
    <Background>
      <LeftSide>
        {errorHappened ? 
        <FormError>
          <strong>{t('ERROR')}</strong> {t('Email address could not be changed.')}
        </FormError>
        : null}
        {successHappened ? 
        <FormSuccess>
          <strong>{t('OK')}</strong> {t('Email address changed succesfully. Redirecting...')}
        </FormSuccess>
        : null}
        <MainBox>
          <MainBoxHeader>{t('Change your email')}</MainBoxHeader>
          <FormContent>
            {t('change-your-email-info')}
          </FormContent>
          <FormContainer
            action=""
            onSubmit={e => {
              e.preventDefault()
              changePassword()
            }}
          >
            <FormRow>
              <FormContent>
                <label htmlFor="new_username">{t('New email')}</label>
                <StyledInputText
                  placeholder={t('New email')}
                  id="new_username"
                  type="email"
                  value={new_username}
                  onChange={e => setNewUsername(e.target.value)}
                />
              </FormContent>
            </FormRow>
            <FormRow>
              <FormContent>
                <label htmlFor="password">{t('Type your password')}</label>
                <StyledInputText
                  placeholder={t('Type your password')}
                  autoComplete={"off"}
                  id="password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </FormContent>
            </FormRow>
            <FormActionButtonGroup>
              <WhiteButtonLink to={`/personal-info`}>{t('Cancel')}</WhiteButtonLink>
              <BlueButton>{t('Change your email')}</BlueButton>
            </FormActionButtonGroup>
          </FormContainer>
        </MainBox>
      </LeftSide>
      <RightSide>
        <ContactForm />
      </RightSide>
    </Background>
  )
}

export default EmailChangeScreen
