import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../Store'

const LoginHeader = styled.div`
  margin: 0;
  background-color: #2A4B6B;
  color: #FFF;
  text-align: right;
  position: absolute;
  top: 0;
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  justify-content: right;
  
  span {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  a {
    text-decoration: none;
  }
  
  @media print {
    display: none;
  }
`

const AuthHeaderBlock = () => {
  const { t } = useTranslation()
  const { authed } = useSelector((state: RootState) => state.auth)

  if (authed) {
    return (
      <LoginHeader>
        <span>
          <Link to={`/personal-info`}>{t('my_information_title')}</Link>
        </span>
        |
        <span>
          <Link to={'/migration-logout'}>{t('Sign out')}</Link>
        </span>
      </LoginHeader>
    )
  } else {
    return (
      <LoginHeader>
        <span>
          <Link to={`/migration-login?login`}>{t('Log in')}</Link>
        </span>
        |
        <span>
          <Link to={`/migration-login?registration`}>{t('Registration')}</Link>
        </span>
      </LoginHeader>
    )
  }
}

export default AuthHeaderBlock
