import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import { useDispatch } from 'react-redux'

import authReducer from 'Features/Auth/authSlice'
import searchReduer from 'Features/Search/searchSlice'
import paymentReducer from 'Features/Payment/paymentSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    search: searchReduer,
    payment: paymentReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
