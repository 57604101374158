import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  AsyncThunk,
  createAction
} from '@reduxjs/toolkit'
import { getAxiosAPIInstance } from 'Api'
import { AxiosError } from 'axios'
import { fetchInitialSettings, handleError } from 'Features/Auth/authSlice'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

export interface RegistryEntry {
  //common
  id: number | undefined
  entry_id: number
  last_name: string | null
  first_names: string | null
  nationality: string | null
  profession: string | null
  additional_information: string | null
  registryName: string | null
  registry: any

  registry_information: any | null

  //australian_finns
  date_of_birth: string | null
  birthplace: string | null
  birth_province: string | null
  arrival_time: number | null
  marital_status: string | null
  spouse: string | null
  australian_address: string | null
  australian_profession: string | null
  proficiency_in_english: string | null
  itinerary: string | null
  australian_residences: string
  matrikk_no: string | null
  konsul_tieto: string | null
  passport_date: string | null
  other_passports: string | null
  sho_id: number | null
  date_of_departure: string | null
  age: string | null
  route_price: number
  other_tickets: string | null
  ska_information: string | null
  swedish_archival_information: string | null

  //kaski_finns
  middle_name: string | null
  resident_in_finland: string | null
  migration: string | null
  residential_village_in_finland: string | null
  resident_in_sweden: string | null
  residential_village_in_sweden: string | null
  other_migratory_destination: string | null
  sources: string | null

  //leitzinger_naturalisations
  decision_date: string | null
  family: string | null
  oath_date: string | null
  original_data: string | null
  place: string | null

  //new_zealand_finns
  date_of_death: string | null
  date_of_naturalization: string | null
  notes: string | null
  occupied: string | null
  parish: string | null
  residence: string | null

  //passenger_list
  departure_e: string | null
  destination: string | null
  destination_country: string | null
  destination_state: string | null
  gender: string | null
  line: string | null
  list_number: string | null
  note: string | null
  place_of_departure: string | null
  port_of_departure: null
  price: string | null
  route: string | null
  ship: string | null
  ship_e: string | null

  //passport_list
  mikrof_nr: string | null
  issued_date: string | null
  estate: string | null
  home_region: string | null
  religion: string | null
  duration: string | null
  passport_number: number | null
  passport_issuer: string | null
  station: string | null
  date: string | null

  //reference_database
  alias: string | null
  country_of_birth: string | null
  country_of_death: string | null
  place_of_death: string | null
  recorder: string | null
  references: string | null
  state_of_birth: string | null
  state_of_death: string | null
  time_of_death: string | null

  //russian_finnish_persecution_victims
  year_of_birth: string | null
  memo: string | null
  source: string | null
  reference: string | null

  //martyrology_of_ingrian_finns
  all_references: any
}
export interface SingleResult {
  registryName: string
  registry: any
}
interface SearchState {
  entities: { [key in RegistryTypes]: Partial<RegistryEntry>[] } | {}
  registryFilter: string | undefined
  singleResult: Partial<RegistryEntry>[]
  suggestedResults: { [key in RegistryTypes]: Partial<RegistryEntry>[] } | {}
  currentPage: number
  formData: Partial<SearchOptions>
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  initialSearchHasBeenMade: boolean
  error: null | undefined | FetchErrors
  user_guides: any
  searchSettings: any
  search_params: any
}
export const initialState: SearchState = {
  entities: {},
  registryFilter: undefined,
  singleResult: [],
  suggestedResults: {},
  currentPage: 0,
  formData: {},
  loading: 'idle',
  initialSearchHasBeenMade: false,
  error: null,
  user_guides: {
    fi: [],
    sv: [],
    en: []
  },
  searchSettings: {
    page: 0, 
    pageSize: 50
  },
  search_params: {
    birthplace: [],
    destination_country: [],
    destination: [],
    home_region: [],
  }
}

export type RegistryTypes =
  | 'australian_finns'
  | 'passenger_list'
  | 'passport_list'
  | 'new_zealand_finns'
  | 'leitzinger_naturalizations'
  | 'russian_finnish_persecution_victims'
  | 'martyrology_of_ingrian_finns'
  | 'kaski_finns'
  | 'reference_database'

export type SearchResponse = Record<RegistryTypes, RegistryEntry[]>
export type SearchOptions = Partial<RegistryEntry> & {
  registry?: RegistryTypes[]
}
export type FormData = Partial<SearchOptions>
export type singleSearchOptions = {
  registry: RegistryTypes
  id: number
}
export type singleSearchResponse = Partial<RegistryEntry>
type SuggestedResultsOptions = Partial<RegistryEntry> & {
  currentId: number
}
export interface FetchErrors {
  status: number
  message: string
}

const SUGGESTED_RESULTS_MAX = 10
const ISSUE_TO_DEPARTURE_DAYS_MAX = 62
const MS_PER_DAY = 1000 * 60 * 60 * 24

const setFormData = createAction<any>('search/setFormData')
const changePage = createAction<any>('search/changePage')
const setRegistryFilter = createAction<any>('search/setRegistryFilter')

const searchByParams = createAsyncThunk<
  SearchResponse,
  SearchOptions,
  {
    rejectValue: FetchErrors
  }
>('search/byParams', async (params: SearchOptions, { rejectWithValue }) => {
  try {
    const response = await getAxiosAPIInstance().get<SearchResponse>(
      `/api/search/`,
      {
        params: {
          ...params
        }
      }
    )

    return response.data
  } catch (error) {
    return handleError(error, rejectWithValue)
  }
})

const getDateFromEntryString = (dateString: string | null | undefined) => {
  const dateSplit = dateString?.split('.')
  return dateSplit && dateSplit.length === 3
    ? new Date(dateSplit[2] + '-' + dateSplit[1] + '-' + dateSplit[0])
    : null
}

/**
 * @param a
 * @param b
 * @returns The difference in days
 */
const getDateDiffInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY))
}

/**
 * For excluding results where there is more than a defined period of time
 * between issuing the passport and leaving the country
 * @param dateOfDeparture The date when the person left the country
 * @param issuedDate The date when the passport was issued
 * @returns true if the dates cannot be compared or the difference in dates is
 *          within limits
 */
const isPassportIssueDateToDepartureDateWithinLimits = (
  dateOfDeparture: Date | null,
  issuedDate: Date | null
) => {
  return !dateOfDeparture || !issuedDate || getDateDiffInDays(issuedDate, dateOfDeparture) <= ISSUE_TO_DEPARTURE_DAYS_MAX;
}

const searchSuggestedResults = createAsyncThunk<
  SearchResponse,
  SuggestedResultsOptions,
  {
    rejectValue: FetchErrors
  }
>(
  'search/suggestedResults',
  async (params: SuggestedResultsOptions, { rejectWithValue }) => {
    try {
      const response = await getAxiosAPIInstance().get<SearchResponse>(
        `/api/search/`,
        {
          params: {
            first_names: params.first_names,
            last_name: params.last_name
          }
        }
      )
      
      const dateOfDeparture = getDateFromEntryString(params.date_of_departure)
      const issuedDate = getDateFromEntryString(params.issued_date)
      const results = {} as SearchResponse
      let numberOfEntries = 0
      let index = 0

      /* If false there are no more entries in the
         response to include in the results */
      let moreResults = true

      outer: while (moreResults) {
        moreResults = false

        for (const [registry, entries] of Object.entries(response.data)) {
          /* Loop the entries so that there are results from all registries
             evenly */

          if (numberOfEntries >= SUGGESTED_RESULTS_MAX) {
            break outer
          }

          if (index < entries.length) {
            moreResults = true

            const entry = entries[index]

            if (entry.id === params.currentId) {
              // Skip the entry that is currently being viewed
              continue
            }
            
            /* If passenger list then only show results from passport list and vice versa.
            * */
            if (params.registry === 'passenger_list') {
              if (registry === 'passport_list') {
                if (!isPassportIssueDateToDepartureDateWithinLimits(dateOfDeparture, getDateFromEntryString(entry.issued_date))) {
                  continue
                }
              } else {
                continue
              }
            } else if (params.registry === 'passport_list') {
              if (registry === 'passenger_list') {
                if (!isPassportIssueDateToDepartureDateWithinLimits(issuedDate, getDateFromEntryString(entry.date_of_departure))) {
                  continue
                }
              } else {
                continue
              }
            } else {
              continue
            }
            
            if (registry in results) {
              results[registry as RegistryTypes].push(entry)
            } else {
              results[registry as RegistryTypes] = [entry]
            }

            ++numberOfEntries
          }
        }

        ++index
      }

      return results
    } catch (err: any) {
      let error: AxiosError<FetchErrors> = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data)
    }
  }
)

const searchById = createAsyncThunk<
  singleSearchResponse,
  singleSearchOptions,
  {
    rejectValue: FetchErrors
  }
>(
  'search/byId',
  async ({ registry, id }: singleSearchOptions, { rejectWithValue }) => {
    try {
      const response = await getAxiosAPIInstance().get<singleSearchResponse>(
        `/api/${registry}/${id}/`
      )
      return response.data
    } catch (err: any) {
      let error: AxiosError<FetchErrors> = err
      if (!error.response) {
        throw err
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const REDUCER_NAME = 'search'
const isPendingAction = (action: AnyAction): action is PendingAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/pending')
  )
}
const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/rejected')
  )
}
const isFulfilledAction = (action: AnyAction): action is FulfilledAction => {
  return (
    action.type.startsWith(REDUCER_NAME) && action.type.endsWith('/fulfilled')
  )
}
export const resetAction = createAction('reset-tracked-loading-state-search')
const searchSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: builder => {
    builder
      .addCase(resetAction, state => {
        state.entities = {}
        state.initialSearchHasBeenMade = false
      })
      .addCase(searchByParams.fulfilled, (state, { payload }) => {
        state.error = null
        state.entities = payload
        state.initialSearchHasBeenMade = true
        // Change page to 0 every time a new search request is made
        state.currentPage = 0
      })
      .addCase(searchByParams.rejected, (state, { payload }) => {
        state.error = payload
      })
      .addCase(searchSuggestedResults.pending, state => {
        state.suggestedResults = {}
      })
      .addCase(searchSuggestedResults.fulfilled, (state, { payload }) => {
        state.suggestedResults = payload
      })
      .addCase(searchById.fulfilled, (state, { payload, meta }) => {
        if (state.singleResult !== null) {
          state.singleResult[meta.arg.id] = payload
        }
        state.initialSearchHasBeenMade = true
      })
      .addCase(setFormData, (state, { payload }) => {
        state.formData = payload
      })
      .addCase(changePage, (state, { payload }) => {
        state.searchSettings = payload
      })
      .addCase(fetchInitialSettings.fulfilled, (state, { payload }) => {
        state.user_guides = payload.user_guides
        state.search_params = payload.search_params
      })
      .addCase(setRegistryFilter, (state, { payload }) => {
        state.registryFilter = payload
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending'
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'rejected'
      })
      .addMatcher(isFulfilledAction, state => {
        state.loading = 'fulfilled'
      })
  }
})
export default searchSlice.reducer
export {
  searchByParams,
  searchSuggestedResults,
  searchById,
  setFormData,
  changePage,
  setRegistryFilter
}
