import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface FetchedDataAttribute {
  data: {
    fieldName: string
    fetchedData: string | number | null | undefined
  }
}

const HiddenBar = styled.div`
    height: 1rem;
    width: 20%;
    border: 1px solid #f3f4f7;
    border-radius: 10px;
    background-color: #b8b8b8;
    margin: 0;
    float: right;
    display: block;
  `

const OtherDatabaseListItem = styled.li`
    text-align: left;
    margin-left: 0px;
    padding: 4px 8px;
    &:nth-child(odd) {
      background-color: #f3f4f7;
    }
    span {
      float: right;
      word-wrap: break-word;
      margin-left: auto;
    }
  `

const FetchedDataContainer = ({
  data: { fieldName, fetchedData }
}: FetchedDataAttribute) => {
  const { t } = useTranslation()

  return (
    <OtherDatabaseListItem>
      {fieldName}
      {typeof fetchedData === 'undefined' ? (
        <HiddenBar />
      ) : fetchedData === null ? (
        <span>{t('No data')}</span>
      ) : (
        <span>{fetchedData}</span>
      )}
    </OtherDatabaseListItem>
  )
}

export default FetchedDataContainer
