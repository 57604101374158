import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RegistryEntry } from '../../Features/Search/searchSlice'

const Container = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1rem;
`
const Title = styled.span`
  font-weight: bold;
  font-size: 1.35rem !important;
  line-height: 1.35rem;
  margin: 0;
  padding-bottom: 0rem !important;
`
const Content = styled.div`
  font-size: 1.35rem;

  a {
    margin-top: 0.25rem;
    text-decoration: none;
    display: block;
    color: #41a2db;
  }

  a:visited {
    color: #41a2db;
  }

  a:hover {
    text-decoration: underline;
  }
`
const SingleSuggestedReferenceResult = (props: Partial<RegistryEntry>) => {
  const { t } = useTranslation()
  const queryParams = useLocation().search
  const firstName = props.first_names || ''
  const lastName = props.last_name || ''
  const registryName = props.registry || ''
  return (
    <Container>
      <Title>{`${firstName} ${lastName}`}</Title>
      <Content>
        {t(`${registryName}`)}
        <Link to={`/${registryName}/${props.id}/${queryParams}`} replace>
          {t('Show detailed view')} &#10095;
        </Link>
      </Content>
    </Container>
  )
}

export default SingleSuggestedReferenceResult
