import styles from './SubscribeService.module.scss'
import { Col, Row } from 'Components/Layout/Grid'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SubscribeService = () => {
  const { t } = useTranslation()
  return (
    <Row className={styles.background}>
      <Col size={4}>
        <button type="button">{t('Subscribe service')}</button>
        <p className={styles.gotEm}>
          {t('Have account')}
          <Link to="/migration-login"> {t('Login from')}</Link>
        </p>
      </Col>
      <Col size={6}>
        <div className={styles.featuresContainer}>
          <h2>{t('Subscription advantages header')}</h2>
          <ul>
            <li>{t('Subscription advantage #1')}</li>
            <li>{t('Subscription advantage #3')}</li>
          </ul>
          <div className={styles.price}>
            <h5>{t('Subscription fee header')}</h5>
            <p>
              {t('Subscription fee price')}
              <span>{t('Subscription fee price ending')}</span>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const SubscribeServiceLogin = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.subscribe}>
      <div>
        <h4>{t('Subscription advantages header')}</h4>
        <ul>
          <li>{t('Subscription advantage #1')}</li>
          <li>
            {t('Subscription advantage #2')}{' '}
            <a href={t('Subscription advantage #3 link')}>
              {t('Subscription advantage #3')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { SubscribeService as default, SubscribeServiceLogin }
