import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../Store'

const Container = styled.div`
  background-color: #FFF5E2;
  display: flex;
  padding: 12px;
  color: #944700;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    margin: 20px 0;
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const TopBlockSmall = () => {
  const { t } = useTranslation()
  const { active_subscription } = useSelector((state: RootState) => state.auth)

  if (!active_subscription) {
    return (
      <Container>
        <span>
          {t('Limited to #2')} &nbsp;
          <Link to={`/migration-login?login`}>{t('Login')}</Link>&nbsp;{t('or')}&nbsp;
          <Link to={`/migration-login?registration`}>{t('create an account')}</Link>.
        </span>
      </Container>
    )
  } else {
    return <></>
  }
}

export default TopBlockSmall
