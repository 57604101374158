import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const Background = styled.div`
  color: #000;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  a {
    color: #000;
    font-weight: normal;
  }
`

const WelcomeIcon = styled.div`
  font-size: 4rem;
  padding: 1rem;
  color: #099bd9;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  text-align: left;
`
const Content = styled.div``

const Button = styled.button`
  margin: auto;
  border: none;
  padding: 10px 20px;
  border-radius: 20.5px;
  background-color: #099bd9;
  text-align: center;
  color: #fff;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
`

const CallToActionContainer = styled.div`
  margin-top: 1rem;
`

const FirstStep = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Background>
      <ContentContainer>
        <WelcomeIcon>
          <i className="fas fa-lock-alt" />
        </WelcomeIcon>
        <Content>
          {t('Payment expired')}
          <br />
          <br />
          {t('Opt to use free search')}&nbsp;
          <Link to={'/migration-logout'}>{t('By signing out')}</Link>.
        </Content>
      </ContentContainer>
      <CallToActionContainer>
        <Button onClick={() => history.push('/payment')}>
          {t('Opt to continue subscription')}
        </Button>
      </CallToActionContainer>
    </Background>
  )
}

export default FirstStep
