import { FC } from 'react'
import { useState, ChangeEvent, MouseEvent } from 'react'
import SearchResult from 'Components/SearchResults/SearchResult'
import { useTranslation } from 'react-i18next'

import { RootState, useAppDispatch } from 'Store'

import {
  searchByParams,
  setFormData,
  SearchOptions,
  RegistryTypes,
  resetAction
} from 'Features/Search/searchSlice'
import styled from 'styled-components'
import {
  InputComponent,
  CheckboxComponent
} from '../SearchResults/InputComponent'
import LockedFieldsNotificationContainer from './LockedFieldNotification'
import { useSelector } from 'react-redux'

interface DisabledLabel {
  blocked: boolean
}

const DisabledLabelContainer = styled.div<DisabledLabel>`
  text-align: left;
  padding: 0.5rem 0;
  color: ${props => (props.blocked ? '#AFAFAF' : 'initial')};
`

const OuterMostContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 70%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  display: flex;
  height: min-content;
  min-width: 375px;
  width: 375px;
  flex-direction: column;
  background-color: #f3f4f7;
  padding: 32px;

  @media only screen and (max-width: 1200px) {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
`
const FormTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`

const FormTitle = styled.h6`
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ShowMoreOrLess = styled.div`
  text-align: left;
  color: #45a4dc;
  cursor: pointer;
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem 0;
`

const SearchButton = styled.button`
  border-radius: 18px;
  border: none;
  background-color: #2a4b6b;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-size: 1.25rem;
  display: block;
  cursor: pointer;
  outline: none;
  line-height: 36px;
  width: 200px;
  justify-self: center;
`

const ClearButton = styled.div`
  color: #005796;
  transition: 0.25s;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  label {
    display: block;
    width: fit-content;
    text-align: left;
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }
  input {
    border-radius: 2px;
    padding-left: 17px;
    height: 36px;
    width: 100%;
    display: block;
    &::placeholder {
      font-style: italic;
    }
  }
`

const InputInfo = styled.div`
  text-align: left;
  font-size: 1.1rem;
  padding-top: 1rem;
  color: #6c6c6c;
`

const Checkboxes = styled.div`
  transition: 0.4s ease-out;
  margin-top: 25px;
  margin-left: 0px;
  h3 {
    color: #183e6f;
    font-size: 18px;
    text-align: left;
    font-weight: 500;
  }
  > div {
    display: flex;
    align-items: flex-start;
  }

  input[type='checkbox'] {
    cursor: pointer;
    text-align: left;
    height: 24px;
    width: 24px;
    margin-top: 9px;
    margin-right: 9px;
    &:checked {
      background-color: #183e6f;
      color: #fff;
    }
  }
  label {
    text-align: left;
    padding: 12px 0 0 0;
  }
`

const Search: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { active_subscription } = useSelector((state: RootState) => state.auth)
  const { formData, search_params } = useSelector((state: RootState) => state.search)

  const setFormDataTest = (formData: any) => {
    dispatch(setFormData(formData))
  }

  const [quickSearch, setQuickSearch] = useState(false)

  const performSearch = () => {
    dispatch(searchByParams(formData))
  }

  const disableSearch = Object.keys(formData).length === 0

  const changeSearchState = () => {
    setFormData({})
    setQuickSearch(!quickSearch)
  }

  const clearForm = (e: MouseEvent) => {
    e.preventDefault()
    dispatch(setFormData({}))
    dispatch(resetAction())
  }

  const updateFormFieldValues = (event: ChangeEvent<HTMLInputElement>, autocompleteValue?: any, fieldName?: any) => {
    const {
      target,
      target: { name, value, type }
    } = event
    
    let cleanName = name as keyof SearchOptions
    const isCheckbox = type === 'checkbox'
    const isChecked = target.checked
    let cleanValue = value
    
    if (autocompleteValue && (event.type === "change" || event.type === "click")) {
      cleanValue = autocompleteValue
      
      if (event.type === "click" && fieldName) {
        cleanName = fieldName
      }
    }

    let newFormData: SearchOptions = {}

    if (isCheckbox) {
      const checkboxName = cleanValue as RegistryTypes
      let current = Array.isArray(formData.registry) ? formData.registry : []
      current = current.slice(0)

      if (isChecked) {
        current.push(checkboxName)
      } else {
        current = current.filter(item => item !== checkboxName)
      }
      newFormData = { ...formData, ...{ registry: current } }
    } else {
      newFormData = { ...formData, [cleanName]: cleanValue }
    }

    if (!cleanValue) {
      delete newFormData[cleanName]
    }

    setFormDataTest(newFormData)
  }

  const quickAccessFormInputInformation = [
    {
      label: t('first_names'),
      name: 'first_names',
      placeholder: t('first_names'),
      type: 'text',
      tooltip: t('We recommend that you write only the first 2-3 letters of the person\'s first name.')
    },
    {
      label: t('last_name'),
      name: 'last_name',
      placeholder: t('Last name'),
      type: 'text',
      tooltip: t('We recommend that you write only the first 2-3 letters of the person\'s first name.')
    }
  ]

  const formInputInformation = [
    {
      label: t('Birth date'),
      name: 'date_of_birth',
      placeholder: t('Birth date'),
      type: 'text',
      available_on_registries: [
        'australian_finns',
        'new_zealand_finns',
        'russian_finnish_persecution_victims',
        'martyrology_of_ingrian_finns',
        'passport_list',
        'reference_database'
      ],
      tooltip: ''
    },
    {
      label: t('Birth place'),
      name: 'birthplace',
      placeholder: t('Birth place'),
      type: 'autocomplete',
      available_on_registries: [
        'australian_finns',
        'russian_finnish_persecution_victims',
        'martyrology_of_ingrian_finns',
        'reference_database'
      ],
      options: search_params['birthplace'],
      tooltip: ''
    },
    {
      label: t('Country of destination'),
      name: 'destination_country',
      placeholder: t('Country of destination'),
      type: 'autocomplete',
      available_on_registries: ['passport_list', 'passenger_list'],
      options: search_params['destination_country'],
      tooltip: ''
    },
    {
      label: t('destination'),
      name: 'destination',
      placeholder: t('City of destination'),
      type: 'autocomplete',
      available_on_registries: ['passenger_list'],
      options: search_params['destination'],
      tooltip: ''
    },
    {
      label: t('Residence before migration'),
      name: 'home_region',
      placeholder: t('residence'),
      type: 'autocomplete',
      available_on_registries: ['passport_list'],
      options: search_params['home_region'],
      tooltip: ''
    },
    {
      label: t('date_of_departure'),
      name: 'date_of_departure',
      placeholder: t('In the form of DD.MM.YYYY'),
      type: 'text',
      available_on_registries: ['passenger_list', 'australian_finns'],
      tooltip: ''
    },
    {
      label: t('Quick search'),
      name: 'quick_search',
      placeholder: t('Keyword field'),
      type: 'text',
      info: t(
        '* Use full words or dates on keyword field to get best search results.'
      ),
      available_on_registries: [
        'passenger_list',
        'australian_finns',
        'kaski_finns',
        'leitzinger_naturalizations',
        'new_zealand_finns',
        'russian_finnish_persecution_victims',
        'martyrology_of_ingrian_finns',
        'passport_list',
        'reference_database'
      ],
      tooltip: ''
    }
  ]

  const formCheckboxInformation = [
    {
      label: t('passenger_list'),
      name: 'registry',
      value: 'passenger_list',
      type: 'checkbox'
    },
    {
      label: t('passport_list'),
      name: 'registry',
      value: 'passport_list',
      type: 'checkbox'
    },
    {
      label: t('reference_database'),
      name: 'registry',
      value: 'reference_database',
      type: 'checkbox'
    },
    {
      label: t('australian_finns'),
      name: 'registry',
      value: 'australian_finns',
      type: 'checkbox'
    },
    {
      label: t('new_zealand_finns'),
      name: 'registry',
      value: 'new_zealand_finns',
      type: 'checkbox'
    },
    {
      label: t('russian_finnish_persecution_victims'),
      name: 'registry',
      value: 'russian_finnish_persecution_victims',
      type: 'checkbox'
    },
    {
      label: t('leitzinger_naturalizations'),
      name: 'registry',
      value: 'leitzinger_naturalizations',
      type: 'checkbox'
    },
    {
      label: t('kaski_finns'),
      name: 'registry',
      value: 'kaski_finns',
      type: 'checkbox'
    },
    {
      label: t('martyrology_of_ingrian_finns'),
      name: 'registry',
      value: 'martyrology_of_ingrian_finns',
      type: 'checkbox'
    }
  ]
  
  return (
    <OuterMostContainer>
      <FormContainer>
        <FormTitleContainer>
          <FormTitle>{t('Search criteria')}</FormTitle>
          <ClearButton key={'clear'} onClick={clearForm}>
            {t('Clear the search form')}
          </ClearButton>
        </FormTitleContainer>
        <Form
          action=""
          onSubmit={e => {
            e.preventDefault()
            performSearch()
          }}
        >
          <Fields>
            {quickAccessFormInputInformation.map(
              ({ name, type, label, placeholder, tooltip }) => {
                return (
                  <>
                    <InputComponent
                      key={'free' + label}
                      onChange={updateFormFieldValues}
                      type={type}
                      label={label}
                      placeholder={placeholder}
                      value={formData[name as keyof SearchOptions] || ''}
                      name={name}
                    />
                  </>
                )
              }
            )}
            {quickSearch ? (
              <>
                <LockedFieldsNotificationContainer
                  hidden={active_subscription}
                />
                {formInputInformation.map(
                  ({
                    name,
                    type,
                    label,
                    placeholder,
                    info,
                    available_on_registries,
                    options,
                    tooltip,
                  }) => {
                    /* Check if field should be available with currently selected registries.
                     *
                     * If no register is selected all fields are always available. Single fields
                     * availability per registry is controlled by available_on_registries -attribute.
                     *
                     * */
                    let fieldIsAvailable = available_on_registries.some(
                      item => {
                        if (!formData.registry) return true
                        return formData.registry.indexOf(item) > -1
                      }
                    )

                    if (!formData.registry || formData.registry.length < 1)
                      fieldIsAvailable = true

                    return (
                      <>
                        <InputComponent
                          key={'index' + label}
                          blocked={!active_subscription || !fieldIsAvailable}
                          onChange={updateFormFieldValues}
                          type={type}
                          label={label}
                          placeholder={placeholder}
                          value={formData[name as keyof SearchOptions] || ''}
                          name={name}
                          options={options}
                        />
                        {info ? (<InputInfo>{info}</InputInfo>) : null}
                      </>
                    )
                  }
                )}

                <Checkboxes>
                  <DisabledLabelContainer blocked={!active_subscription}>
                    {t('Select registry')}
                  </DisabledLabelContainer>
                  <InputInfo>{t('registry_selection_info_text')}</InputInfo>
                  {formCheckboxInformation.map(
                    ({ name, type, value, label }) => {
                      return (
                        <CheckboxComponent
                          key={'checkbox' + value}
                          blocked={!active_subscription}
                          onChange={updateFormFieldValues}
                          type={type}
                          label={label}
                          value={value}
                          checked={
                            (formData &&
                              formData.registry &&
                              formData.registry.includes(value)) ||
                            false
                          }
                          name={name}
                        />
                      )
                    }
                  )}
                </Checkboxes>
              </>
            ) : null}
          </Fields>
          <ShowMoreOrLess onClick={changeSearchState}>
            {quickSearch
              ? t('Fewer search options') + String.fromCharCode(8593)
              : t('More search options') + String.fromCharCode(8595)}
          </ShowMoreOrLess>
          <SearchButton disabled={disableSearch}>{t('Search')}</SearchButton>
        </Form>
      </FormContainer>
      <SearchResult />
    </OuterMostContainer>
  )
}

export default Search
