import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

import styled from 'styled-components'

const NoResults = styled.h2`
  margin-top: 10px;
  text-align: left;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`

const UserSurveyLink = styled.a`
  border-radius: 18px;
  background: #2a4b6b;
  color: white;
  text-decoration: none;
  padding: 5px 15px;
  font-weight: normal;
`

const GuideBoxBase = styled.div<{boxColour: string, boxStyle: string}>`
  padding: 32px;
  margin-top: 8px;
  align-self: stretch;
  
  color: ${props => (props.boxColour === "blue" ? '#FFFFFF' : '#000000')};
  background-color: ${props => (props.boxColour === "blue" ? '#2A4B6B' : '#F3F4F7')};
  
  flex-basis: ${props => (props.boxStyle === "full" ? '100%' : 'calc(50% - 8px)')};
  
  &:first-child {
    margin-top: 0px;
  }
  
  p {
    padding: 16px 0;
  }
  
  h3 {
    color: ${props => (props.boxColour === "blue" ? '#FFFFFF' : '#183E6F')} !important;
  }
  
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
  
`

interface GuideItem {
  title: string
  content: string
  box_colour: string
  box_style: string
}

const GuideboxesScreen = () => {
  const { t, i18n } = useTranslation()
  const {
    auth: {
      entities: { user_survey_link }
    },
    search: {
      initialSearchHasBeenMade,
      user_guides,
    },
  } = useSelector((state: RootState) => state)
  
  let noResultsElement = <NoResults>{t('No results')}</NoResults>

  const contentBoxes = user_guides[i18n.language].map((item: GuideItem) => {
    return (
      <GuideBoxBase boxColour={item.box_colour} boxStyle={item.box_style}>
        <h3>{item.title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: item.content
          }}
        />
      </GuideBoxBase>
    )
  })
  
  return (
    <>
      <HeaderContainer>
        {initialSearchHasBeenMade ? noResultsElement : contentBoxes}
        {user_survey_link && (
          <UserSurveyLink
            href={user_survey_link.url}
            target="_blank"
            rel="noreferrer"
          >
            {user_survey_link.link_text[i18n.language]}
          </UserSurveyLink>
        )}
      </HeaderContainer>
    </>
  )
}

export default GuideboxesScreen
