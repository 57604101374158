import styled from 'styled-components'
import SingleEntryAttributeContainer from './SingleEntryAttribute'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 1rem 0;
  font-size: 1.35rem;

  a {
    margin-top: 1rem;
    text-decoration: none;
    display: block;
    color: #41a2db;
  }

  a:visited {
    color: #41a2db;
  }

  a:hover {
    text-decoration: underline;
  }
`

const CompressedContainer = styled(Container)`
  @media only screen and (min-width: 681px) {
    width: 100%;
  }

  min-height: 75px;

  h3 {
    font-size: 1.35rem !important;
  }

  padding: 0.25rem;

  a {
    margin-top: 0;
  }
`

const CompressedContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  span {
    width: 50%;
  }
`

const Title = styled.h3``

const CompressedContent = styled.div`
  display: flex;
  width: 100%;
`

const nonListedFields = ['last_name', 'first_names']

// Get list of attributes to show
export const getFields = (
  fieldMapper: { [index: string]: { [index: string]: string } },
  registryName: string,
  registry: any,
  compressed: boolean = false
): any => {
  const keys: string[] = Object.keys(fieldMapper)
  if (keys.includes(registryName)) {
    return Object.keys(fieldMapper[registryName]).map((fieldName, key) => {
      const name = fieldMapper[registryName][fieldName]
      const value = registry[fieldName]
      if (nonListedFields.includes(fieldName)) return false
      return (
        <SingleEntryAttributeContainer
          key={name + value + key}
          name={name}
          value={value}
          hidden={false}
          compressed={compressed}
        />
      )
    })
  }
  return []
}

interface SingleResult {
  registryName: string
  registry: any
  compressed: boolean
}

const SingleResultContainer = (props: SingleResult) => {
  const { t } = useTranslation()
  const queryParams = useLocation().search

  const fieldMapper: { [index: string]: { [index: string]: string } } = {
    passenger_list: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      destination: t('destination'),
      destination_country: t('destination_country'),
      destination_state: t('destination_state'),
      date_of_departure: t('date_of_departure')
    },
    passport_list: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      issued_date: t('passport_date'),
      date_of_birth: t('date_of_birth'),
      home_region: t('residence'),
      destination_country: t('destination_country')
    },
    reference_database: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      alias: t('alias'),
      birthplace: t('birthplace'),
      country_of_birth: t('country_of_birth')
    },
    australian_finns: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      date_of_birth: t('date_of_birth'),
      birthplace: t('birthplace'),
      arrival_time: t('arrival_time')
    },
    new_zealand_finns: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      date_of_birth: t('date_of_birth'),
      parish: t('parish'),
      arrival_time: t('arrival_time')
    },
    leitzinger_naturalizations: {
      last_name: t('last_name'),
      first_names: t('first_names'),
      nationality: t('nationality'),
      oath_date: t('oath_date')
    },
    kaski_finns: {
      last_name: t('last_name'),
      first_names: t('first_names')
    }
  }
  const { registry, registryName, compressed } = props
  const fields = getFields(fieldMapper, registryName, registry, compressed)
  const id = registry['id'] || null
  const firstName = registry['first_names'] || ''
  const lastName = registry['last_name'] || ''
  let singleEntry = (
    <>
      <SingleEntryAttributeContainer
        name={t('Registry')}
        value={t(`${registryName}`)}
        hidden={false}
        compressed={compressed}
      />
      {fields}
    </>
  )

  if (compressed) {
    singleEntry = <CompressedContent>{fields}</CompressedContent>
  }

  return compressed ? (
    <CompressedContainer>
      <CompressedContainerHeader>
        <span>{`${firstName} ${lastName}`}</span>
        <span>{t(`${registryName}`)}</span>
      </CompressedContainerHeader>
      {singleEntry}
      <Link to={`${registryName}/${id}/${queryParams}`}>
        {t('Show detailed view')} &#10095;
      </Link>
    </CompressedContainer>
  ) : (
    <Container>
      <Title>{`${firstName} ${lastName}`}</Title>
      {singleEntry}
      <Link to={`${registryName}/${id}/${queryParams}`}>
        {t('Show detailed view')} &#10095;
      </Link>
    </Container>
  )
}

export default SingleResultContainer
