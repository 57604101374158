import { RootState } from '../Store'
import { useSelector } from 'react-redux'
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const HeaderContainer = styled.div`
  border-bottom: 1px solid #2A4B6B;
  margin-bottom: 16px;
`

const Header = () => {
  const { navigation } = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation()
  const navigationLinksDesktop = navigation.map((item: any) => {
    const children = item.children
      ? item.children.map((children: any) => (
          <li className="menu-item menu-item-type-post_type menu-item-object-page">
            <a href={children.url}>{children.title}</a>
          </li>
        ))
      : null

    return (
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
        <a href={item.url}>{item.title}</a>
        {children ? <ul className="sub-menu">{children}</ul> : null}
      </li>
    )
  })

  const navigationLinksMobile = navigation.map((item: any) => {
    const children = item.children
      ? item.children.map((children: any) => (
          <li className="menu-item menu-item-type-post_type menu-item-object-page">
            <a href={children.url}>{children.title}</a>
          </li>
        ))
      : null

    return (
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
        <a href={item.url}>{item.title}</a>
        <span className="menu__item__dropdown">
          <span className="cross"></span>
        </span>
        {children ? <ul className="sub-menu">{children}</ul> : null}
      </li>
    )
  })

  return (
    <HeaderContainer>
      <header className="desktop">
        <div className="main-nav-container">
          <a
            className="home-logo"
            href="https://siirtolaisuusinstituutti.fi/"
            rel="home"
          >
            <img
              className="logo"
              src={t('logo-href')}
              alt="Siirtolaisuusinstituutti logo"
            />
          </a>
          <div className="menu-main-menu-fi-container">
            <ul id="menu-main-menu-fi" className="navigation">
              {navigationLinksDesktop}
            </ul>
          </div>
          <div className="search-button">
            <img
              className="search"
              src="https://siirtolaisuusinstituutti.fi/wp-content/themes/siirtolaisuusinstituutti-theme/assets/img/search_blue.svg"
              alt="Search logo"
            />
            <div className="nav-search">
              <form
                role="search"
                method="get"
                className="search-form"
                action="https://siirtolaisuusinstituutti.fi/"
              >
                <label>
                  <input
                    type="text"
                    className="search-field"
                    placeholder="Hae:"
                    value=""
                    name="s"
                  />
                </label>
                <input type="submit" className="search-submit" value="Hae" />
              </form>
            </div>
          </div>
          <div className="header__languages">
            <ul>
              <li className="lang-item lang-item-3 lang-item-fi current-lang lang-item-first">
                <a lang="fi" hrefLang="fi" href="?lang=fi">
                  Fi
                </a>
              </li>
              <li className="lang-item lang-item-10 lang-item-sv">
                <a lang="sv-SE" hrefLang="sv-SE" href="?lang=sv">
                  Swe
                </a>
              </li>
              <li className="lang-item lang-item-6 lang-item-en">
                <a lang="en-GB" hrefLang="en-GB" href="?lang=en">
                  En
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <header className="header mobile" id="site-header">
        <a className="header__button" href="/#">
          <span className="line line_1"></span>
          <span className="line line_2"></span>
          <span className="line line_3"></span>
        </a>
        <div className="header__title">
          <div className="header__title__inner">
            <h3>menu</h3>
          </div>
        </div>
        <div className="header__content">
          <a href="https://siirtolaisuusinstituutti.fi/" rel="home">
            <img
              className="logo"
              src="https://siirtolaisuusinstituutti.fi/wp-content/themes/siirtolaisuusinstituutti-theme/assets/img/siirtolaisuusinstituutti_logo_blue.jpg"
              alt="Siirtolaisuusinstituutti logo"
            />
          </a>
          <div className="menu-main-menu-fi-container">
            <ul id="menu-main-menu-fi-1" className="navigation">
              {navigationLinksMobile}
            </ul>
          </div>
        </div>
        <div className="header__languages">
          <ul>
            <li className="lang-item lang-item-3 lang-item-fi current-lang lang-item-first">
              <a
                lang="fi"
                hrefLang="fi"
                href="https://siirtolaisuusinstituutti.fi/"
              >
                Fi
              </a>
            </li>
            <li className="lang-item lang-item-10 lang-item-sv">
              <a
                lang="sv-SE"
                hrefLang="sv-SE"
                href="https://siirtolaisuusinstituutti.fi/sv/framsida/"
              >
                Swe
              </a>
            </li>
            <li className="lang-item lang-item-6 lang-item-en">
              <a
                lang="en-GB"
                hrefLang="en-GB"
                href="https://siirtolaisuusinstituutti.fi/en/frontpage-eng/"
              >
                En
              </a>
            </li>
          </ul>
        </div>
        <div className="header__search">
          <form
            role="search"
            method="get"
            className="search-form"
            action="https://siirtolaisuusinstituutti.fi/"
          >
            <label>
              <input
                type="text"
                className="search-field"
                placeholder="Hae:"
                value=""
                name="s"
              />
            </label>
            <input type="submit" className="search-submit" value="Hae" />
          </form>
        </div>
      </header>
    </HeaderContainer>
  )
}

export default Header
