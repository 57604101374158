import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface SingleEntryAttribute {
  name: string
  value: string | null | undefined
  hidden: boolean
  compressed: boolean
}

const EntryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const CompressedEntryContainer = styled.div`
  & > div:first-child {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  & > div {
    font-weight: normal;
  }
  
  width: 25%;
  overflow: hidden;
  
  @media only screen and (min-width: 681px) {
  }

  @media only screen and (max-width: 681px) {
  }
`

/* eslint-disable */
interface EntryValue {
  hidden: boolean
}
/* eslint-enable */

const EntryTitle = styled.div``

const EntryValueContainer = styled.div`
  font-weight: bold;
`

const HiddenBar = styled(EntryValueContainer)`
  height: 1rem;
  width: 25%;
  border: 1px solid #f3f4f7;
  border-radius: 10px;
  background-color: #f3f4f7;
  margin: 0;
  display: inline;
`

const CompressedHiddenBar = styled(HiddenBar)`
  display: block;
  width: 75%;
`

const SingleEntryAttributeContainer = (props: SingleEntryAttribute) => {
  const { t } = useTranslation()
  const NULL_VALUE_EXPRESSION = t('No data')

  let value = props.value === null ? NULL_VALUE_EXPRESSION : props.value

  const compressedEntry = (
    <>
      <EntryTitle title={props.name}>{props.name}</EntryTitle>
      {typeof value === 'undefined' ? (
        <CompressedHiddenBar>{value}</CompressedHiddenBar>
      ) : (
        <EntryValueContainer title={value}>{value}</EntryValueContainer>
      )}
    </>
  )

  const entry = (
    <>
      <EntryTitle title={props.name}>{props.name}</EntryTitle>
      {typeof value === 'undefined' ? (
        <HiddenBar>{value}</HiddenBar>
      ) : (
        <EntryValueContainer title={value}>{value}</EntryValueContainer>
      )}
    </>
  )

  return props.compressed ? (
    <CompressedEntryContainer>{compressedEntry}</CompressedEntryContainer>
  ) : (
    <EntryContainer>{entry}</EntryContainer>
  )
}

export default SingleEntryAttributeContainer
