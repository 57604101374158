import { StrictMode } from 'react';
import ReactDOM from 'react-dom'
import './index.scss'
import './i18n'
import Header from 'Header'
import App from 'App'
import { store } from 'Store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme from 'Components/Layout/Theme'

// theme is also fully typed
const GlobalStyle = createGlobalStyle`
  body,html {
    height: 100%;
    font-family: "museo-sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  #root {
    margin-top: 32px;    
  }
  
  .App, #root {
    min-height: 75vh;
  }

  .App {
    h1,h2,h3,h4,h5,h6 {
      color: ${props => props.theme.colors.primary};
    }
  
    h1 {
      font-size: 40px;
      line-height: 36px;
    }
  
    h2 {
      font-size: 30px;
      line-height: 36px;
    }
  
    h3 {
      font-size: 16px;
    }
    
    h4 {
      font-size: 18px;
      color: #fff;
      line-height: 22px;
    }
    
    h5 {
      color: #6c6c6c;
    }
    
    h6 {
      font-size: 16px;
    }

    input {
      &::placeholder {
        color: #000;
      }
    }
  }
`

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <App />
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
